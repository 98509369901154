<template lang="pug">
  #overview.mx-0.mx-sm-5.py-0.py-sm-5(style="min-height: 100vh")
      b-card.py-3(:class="{'overview-div' : $root.inDesktop}")
        //- Main title & subtitle
        NavButtons
        h1.pt-4.text-center.font-weight-bold.mb-3 Overview of OHFSS Self-Assessment
        p.text-justify
          span This self-assessment is designed to help you identify your current areas of strength and development in organisational health, based on the new framework by NCSS, named 
          b.light-blue-text Organisational Health Framework for Social Services (OHFSS)
          span , which comprises 7 key domains and 32 sub-domains.

        //- Instructions 
        p.text-justify
          h5.font-weight-bold.mb-0 Instructions
          p.text-justify Please give your organisation a rating for each statement from "Limited" to "Excellent". You have the option to save as draft, and return 
            span to complete the form at a later time. At the end of the questionnaire, please leave your feedback in order to complete your submission.
      
        //- Domain and subdomain table on desktop view
        div(v-if="$root.inDesktop")
          b-row
            b-col(md="3")
              h5.ml-2.blue-text Domains
            b-col
              h5.ml-2.orange-text Sub-domains
          div(v-for="(domain, domainIndex) in data" :key="domainIndex")
            b-row.m-0.mb-4
              b-col.center-column(md="3")
                img.desktop-domain-img(:src="domain.image" alt="Image")
                p.text-center.blue-text {{ domain.domain }}
              b-col.center-vertical(:class="{'overview-gradient-bg': domainIndex%2==0}")
                div.pt-2.wrap
                  div.subdomains-div(v-for="(subdomain, index) in domain.subdomain" :key="index") 
                    p.subdomains.p-2.px-2 {{subdomain}} 
        
        //- Domain and subdomain table on phone & tablet view
        div(v-else)
          h5.blue-text Domains
          p Expand to view sub-domains
          div(v-for="(domain, domainIndex) in data" :key="domainIndex")
            b-button(block variant="primary" class="my-1" v-b-toggle="`domain-accordion-${domainIndex}`" ) {{ domain.domain }}
            b-collapse(
              :id="`domain-accordion-${domainIndex}`" 
              :visible="domainIndex === 0" 
              accordion="domain-accordion" 
              role="tabpanel")
                b-row.m-0
                  b-row.m-0.center-column(md="3")
                    img.phone-tablet-domain-img(:src="domain.image" alt="Image")
                    p.text-center.blue-text {{ domain.domain }}
                  b-row.m-0.center-vertical(class='overview-gradient-bg')
                    div.m-2.center.wrap
                      div.subdomains-div(v-for="(subdomain, index) in domain.subdomain" :key="index") 
                        p.p-2.px-2.subdomains {{subdomain}} 
        NavButtons.mt-5.mb-3
        .d-flex.p-2
            TechSupportLine
      
</template>

<script>
import NavButtons from '@/components/navbuttons';
import TechSupportLine from '@/components/technicalSupportLine';
import Domains from '@/config/domainSubdomain.js';
export default {
  name: 'Overview',
  components: {
    NavButtons,
    TechSupportLine
  },
  data(){
    return{
      data: Domains
    }
  },
}
</script>