<template lang="pug">
  div.m-4
    h2 Start your organisational transformation journey by discovering your current state of organisational health.
    h6.text-justify We recommend setting aside 1 hour to complete both the questionnaire and the feedback form. Please use a desktop or tablet for an optimal experience.
    b-button(variant="primary" size= 'lg' @click="next") Start
</template>

<script>
export default {
  name: 'Cover',
  data(){
    return {
      message: ""
    }
  },
  methods:{
    next(){
      this.$router.push({path: '/email'});
    },
  }
}
</script>