<template lang="pug">
    div.d-flex.flex-column.justify-content-center.align-items-center
      vue-step-progress-indicator(
        :colors="colorData" 
        :styles="styleData"
        :steps="$store.state.steps"
        :active-step= "$store.state.step"
        is-reactive=false

      )
</template>

<script>
export default {
  name: 'progressIndicator',
  data(){
    return{
      styleData: {
        progress__wrapper: {
            'justify-content': "center",
        },
      },
      colorData: {
        progress__bubble: {
          active: {
            color: "#FFF",
            backgroundColor: "#F18701",
            borderColor: "#F18701",
          },
          inactive: {
            color: "#1F487E",
            backgroundColor: "#FFF",
            borderColor: "#1F487E",
          },
          completed: {
            color: "#FFF",
            borderColor: "#27AE60",
            backgroundColor: "#27AE60",
          },
        },
          progress__bridge: {
              inactive: {
                  backgroundColor: "#fff",
              },

          },
         progress__label: {
          active: {
            color: "#F18701",
          },
          inactive: {
            color: "#FFF",
          },
          completed: {
            color: "#27AE60",
          },
        },
      }
    }
  }
}
</script>