import angry from '@/assets/images/angry.png';
import moderate from '@/assets/images/moderate.png';
import good from '@/assets/images/happy.png';
import excellent from '@/assets/images/laughing.png';

const questions =  () =>[
    {
        title:"Feedback on User-Experience",
        question: "How was your experience completing the online form?",
        name: "satisfaction",
        selection: [{
            image: angry,
            value: 'Negative',
            path: 'lottie/negative.json'
        }, 
        {
            image: moderate,
            value: 'Moderate',
            path: 'lottie/moderate.json'
        }, 
        { 
            image: good,
            value: 'Good',
            path: 'lottie/good.json'
        }, 
        { 
            image: excellent,
            value: 'Excellent',
            path: 'lottie/excellent.json'
        }],
        textarea: "",
        selectionAnswer: "",
        textareaAnswer: "",
    },
    {
        question: "Did you find the online form easy to navigate?",
        name: "navigation",
        selection:[
            "The online form is very easy to navigate",
            "The online form is mostly easy to navigate",
            "The online form is not easy to navigate",
            "The online form is very difficult to navigate"
        ],
        textarea: "",
        textareaHeader: "",
        selectionAnswer: "",
        textareaAnswer: "",
    },
    {
        question: "Did you find the instructions in the online form easy to understand?",
        name: "instruction",
        selection:[
            "The instructions are very clear and easy to understand",
            "The instructions are mostly clear and easy to understand",
            "The instructions are not that clear and easy to understand",
            "The instructions are very unclear and difficult to understand",
        ],
        textarea: "",
        textareaHeader: "",
        selectionAnswer: "",
        textareaAnswer: "",
    },
    {
        question: "What challenge/s did you face using the online form?",
        name: "challenge",
        selection:[],
        textarea: "What challenge/s did you face using the online form?",
        textareaHeader: "The challenges I faced were: ",
        selectionAnswer: "",
        textareaAnswer: "",
    },
    {
        question: "How can the online form be improved for a better user experience?",
        name: "improvement",
        selection:[],
        textarea: "How can the online form be improved for a better user experience?",
        textareaHeader: "The challenges I faced were: ",
        selectionAnswer: "",
        textareaAnswer: "",
    },
    {
        title:"Feedback on Organisational Health Framework for Social Services (OHFSS)",
        question: "Does the OHFSS questionnaire cover enough aspects of SSAs’ organisational health?",
        name: "factors",
        selection:[
            "The questionnaire covers enough aspects of organisational health",
            "The questionnaire covers most aspects of organisational health",
            "The questionnaire covers a few aspects of organisational health",
            "The questionnaire does not cover enough aspects of organisational health",
        ],
        textarea: "Please share aspects of organisational health you think are missing from the current questionnaire: ",
        textareaHeader: "Factors of organisational health I think are missing from the current questionnaire: ",
        selectionAnswer: "",
        textareaAnswer: "",
    },
    
    {
        question: "Do you find the language used in the OHFSS questionnaire easy to understand?",
        name: "language",
        selection:[
            "The language is very easy to understand",
            "The language is mostly easy to understand",
            "The language is not easy to understand",
            "The language is very difficult to understand",
        ],
        textarea: "Please share some examples of language that can be improved in the OHFSS questionnaire:",
        textareaHeader: "Examples: ",
        selectionAnswer: "",
        textareaAnswer: ""
    },
    {
        question: "What do you think of the length of the OHFSS questionnaire?",
        name: "length",
        selection:[
            "The questionnaire is too long",
            "The questionnaire is long",
            "The questionnaire is of an appropriate length",
            "The questionnaire is too short "
        ],
        textarea: "",
        selectionAnswer: ""
    },
    {
        question: "To what extent do you foresee your organisation using the findings from the Organisational Health Report to strengthen organisational health?",
        name: "findings",
        selection:[
            "To a small extent",
            "To a moderate extent",
            "To a large extent",
        ],
        textarea: "Please elaborate on your response:",
        textareaHeader: "Elaboration: ",
        textareaAnswer: "",
        selectionAnswer: ""
    }
]

export default questions;