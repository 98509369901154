<template lang="pug">
    div
        Domain(:data="data")           
</template>

<script>
import Domain from '@/components/domain.vue';
import Questions from '@/config/financesQuestions.js';

export default {
  name: 'Finances',
  data(){
     return{ 
         data: Questions()
    }
  },
  components: {
      Domain
  }
}
</script>