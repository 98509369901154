import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

import axios from 'axios';
import commPartnershipsQuestions  from '@/config/commPartnershipsQuestions';
import financesQuestions from '@/config/financesQuestions'
import governanceQuestions from '@/config/governanceQuestions';
import peopleQuestions from '@/config/peopleQuestions';
import processDigitalisationQuestions from '@/config/processDigitalisationQuestions';
import strategyLeadershipQuestions from '@/config/strategyLeadershipQuestions';
import userCentricServicesQuestions  from '@/config/userCentricServicesQuestions';
import feedbackQuestions  from '@/config/feedbackQuestions';
import cookiejs from 'js-cookie';
//import Cookies from 'js-cookie';
var qs = require('qs');

const allQuestionsObjects = [
  commPartnershipsQuestions(),
  financesQuestions(),
  governanceQuestions(),
  peopleQuestions(),
  processDigitalisationQuestions(),
  strategyLeadershipQuestions(),
  userCentricServicesQuestions() 
]

const $ = axios.create({
  baseURL:"/api"
});

const feedbackQuestionsArray =  feedbackQuestions();
let feedbackAnswers = {}; // Store feedback answers 

// Feedback answers may have 2 parts -> textarea and selection, which are used to construct full answer
feedbackQuestionsArray.forEach(q =>{
  feedbackAnswers[q.question] = {
    fullAnswer: "",
    textareaAnswer: "", 
    selectionAnswer: ""
  };
})

const userDetails = {
    Title: "",
    SSA: "",
    designation: "",
    userEmail:"",
    headOfAgencyEmail: "",
    headOfAgencyName: "",
    otherDesignation: "",
    diagnosticLead: null,
    isLastRespondent: false,
    invitedEmails: [],
}

const storeConfig = {
    state: {
      appVersion: 1,
      step: 0,
      maxStep: 9,
      steps: ['Overview', 'Strategy & Leadership', 'Governance', 'People',  'Finances', 'Digitalisation', 'Communications & Partnerships',  'User-Centric Services', 'Individual Scoring Summary Report', 'Feedback'],
      lottie:['nurse.json', 'doctor_phone.json' , 'checklist.json', 'doctor.json', 'work.json', 'doctor_phone.json', 'medical_lady.json'],
      motivation:["Let's begin!", "Click, click, click...", "You've got this!", "Going strong!", "Don't give up!", "You're almost there!", "Last one to go!"],
      maxScore: 4,
      userDetails,
      feedbackAnswers,
      allQuestionsObjects,
      isAuthenticated: false,
      authToken: null,
      submitting: false,
      submitted: false,
      loading: false,
    },
    mutations: {
      setSubmitted(state, payload) {
        state.submitted = payload;
      },
      setSubmitting(state, payload) {
        state.submitting = payload;
      },
      setLoading(state, payload){
        state.loading = payload;
      },
      markAuthenticated(state, payload){
        state.isAuthenticated =  true;
        state.authToken = payload;
      },
      setUserDetails(state, payload){
        Object.assign(state.userDetails, payload);
      },
      setStepIndex(state,index) {
        state.step = index;
      },
      setFeedbackAnswers(state, payload){
        // console.log(payload)
        state.feedbackAnswers = payload;
      },
      fillQuestions(state) {
        // JUST FOR TESTING
        for(let obj of state.allQuestionsObjects){
          for (let subobj of obj.subdomain){
            for(let rows of subobj.rows){
              rows.selected = 3;
            }
          }
        }
      }
    },
    actions:{
    async syncData(ctx, userEmail){
        // Sync data with data stored in local storage if there is data
        if(localStorage.getItem(userEmail)){
          let stateObj;
          try {
            stateObj = JSON.parse(localStorage.getItem(userEmail));
          } 
          catch (e) {
            return null;
          }
  
          //If there is no app version -> older draft data before appVersion is implemented cleared
          if (!stateObj.appVersion) {
            localStorage.clear();
            return null;
          }
  
          //If app version is not the latest, older draft data with older appVersion cleared
          if (stateObj.appVersion != ctx.state.appVersion) {
            localStorage.clear();
            return null;
          }
  
          // Set data
          Object.keys(stateObj).map(function(key) {
            ctx.state[key] = stateObj[key];
          });
  
          // Set step index & return current path -> redirect to path in App.vue
          this.commit('setStepIndex', stateObj.step);
          return stateObj.currentPath;
        }
        return null;
    },
    async getUserDetails(ctx, userEmail){
      // Get user details 
      const userData = await this.dispatch('getList',  {where: {userEmail: userEmail}, list: 'user'});    // Get user details 
      const userDetails =  userData.data[0].fields;
      return userDetails;
    },
    async getData(ctx, userEmail){
        // Get user details 
        const userDetails = await ctx.dispatch('getUserDetails', userEmail);
        this.userDetails = userDetails;
        let orgData = null;

        // If user details has org look up ID, get organisation details 
        if(userDetails.orgLookupId){
            const {data} = await this.dispatch('getList',  {list: 'org'});
            orgData = data[0].fields;
        }

        const obj={
            Title: userDetails['Title'],
            SSA: orgData ? orgData['Title']: "",
            designation: userDetails['designation'],
            userEmail: userDetails['userEmail'],
            otherDesignation: ('otherDesignation' in userDetails) ? userDetails['otherDesignation']: "",
            diagnosticLead: userDetails['diagnosticLead'],
            isLastRespondent: userDetails['isLastRespondent'],
        }

        this.commit('setUserDetails', obj);
    },
    async storeInLocalStorage(ctx, payload){
      // Store in local storage 
      this.commit('setLoading', true);
      const stateObj = {
        step: ctx.state.step,
        allQuestionsObjects: ctx.state.allQuestionsObjects,
        currentPath: payload.path,
        feedbackAnswers: ctx.state.feedbackAnswers,
        appVersion: ctx.state.appVersion,
      }
      localStorage.setItem(payload.userEmail, JSON.stringify(stateObj));
      this.commit('setLoading', false);
    },
    async logOut(ctx, location='/'){
      // console.log(location)
      cookiejs.remove('authToken', { path: '/' }); 
      if(window.location.hostname == 'localhost'){
        window.location.href = location;
        return;
      } 
      window.location.href = `/api/logOut/?location=${location}`;
        
        /*
        ctx.state.isAuthenticated = false;
        
        document.cookie = 'authToken' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        console.log('checkCookie', cookiejs.get('authToken'));
        await cookiejs.remove('authToken', { path: '/', domain: window.location.hostname });
        //await $.post('logOut');
        cookiejs.remove('authToken', { path: '/' });
        console.log('checkCookie', cookiejs.get('authToken'));
        */
    },
    async init(){
      // Check if user is logged in
      const {data} =  await $.get(`init`);
      return data;
    },
    async addListItem(ctx, payload){
      // Add a SharePoint list item
      const {data} =  await $.post(`list`, payload, {timeout: 600000});
      return data;
    },
    async getList(ctx, payload) {
      // Get a SharePoint list
      const query = qs.stringify(payload);
      const {data} =  await $.get(`list?${query}`);
      return data;
    },
    async sendVerifyTOTP(ctx, payload) {
      // Send or verify TOTP 
      const {data} =  await $.post(`verify`, payload).catch(err=>{
        return err.response;
      });
      return data;
    },
    async updateList(ctx, payload){
      // Update SharePoint list item
      const {data} =  await $.patch(`list`, payload, {timeout: 600000});
      return data;
    },
    async getFile(){
      // Get Excel file (or any file) from SharePoint Documents
      const {data} =  await $.get(`excelFile`);
      return data;
    },
    async getExcelTableRows(ctx, tableName){
      let payload = {'tableName': tableName};
      const {data} = await $.post('excelTable', payload);
      return data.data; 
    },
    async submitAnswers(ctx){
      // Get number of rows from any table - All tables should have the same amount of rows
      const rowsTableName = 'StrategyLeadership';
      const rows = await ctx.dispatch('getExcelTableRows', rowsTableName);  // returns an array with all entries in the table 
      const name = 'Respondent' + (rows.length+1); 

      // Domain -> Subdomain -> Question
      for(let domainObj of ctx.state.allQuestionsObjects){
        let tableName;
        let domainAnswers=[name];

        switch(domainObj.domain){
          case 'Strategy & Leadership':
            tableName = 'StrategyLeadership';
            break;
          case 'Communications & Partnerships':
            tableName = 'CommPartnerships';
            break;
          case 'Finances':
            tableName = 'Finances';
            break;
          case 'Governance':
            tableName = 'Governance';
            break;
          case 'People':
            tableName = 'People';
            break;
          case 'Digitalisation':
            tableName = 'ProcessDigitalisation';
            break;
          case 'User-Centric Services':
            tableName= 'UserCentricServices';
            break;
        }

        for(let subdomain of domainObj.subdomain){
          for(let question of subdomain.rows){
            domainAnswers.push(question.selected)
          }
        }

        let answerObj = {
          answers: {values: [domainAnswers]},
          table: tableName
        }

        await ($.post('excelWorksheet', answerObj, {timeout: 600000}));
      }
    },
    async submitFeedback(ctx){
      const userEmail = ctx.state.userDetails.userEmail;

      // Feedback payload
      const feedbackPayload={
        list: 'feedback',
        row:{
            Title: userEmail,
        }
      }

      // Feedback question keys are 32 characters long with no space and special characters as _x002f_
      for(let key in ctx.state.feedbackAnswers){
          let trimmedKey = key.replaceAll(' ', '').replaceAll("/", '_x002f_').replaceAll("-", '_x002f_').slice(0,32);
          feedbackPayload.row[trimmedKey] = ctx.state.feedbackAnswers[key].fullAnswer;
      }
      
      await ctx.dispatch("addListItem", feedbackPayload);
    },
    async updateUserStatus(ctx){
      const userEmail = ctx.state.userDetails.userEmail;

      //UAT EXCEPTION FOR THE COMPLETED STATUS (FOR MULTIPLE SUBMISSIONS)
      const exceptionEmails = ['sidrah_ahmad@ncss.gov.sg', 'melissa_wong@ncss.gov.sg', 'lawrence_tng@ncss.gov.sg'];
      const exception = exceptionEmails.includes(userEmail)
      //const userDetails = await ctx.dispatch('getUserDetails', userEmail);

      // Update user submission status payload
      const userPayload = {
        updatedFields: {submissionStatus: exception ? 'Pending' : 'Completed'},
        userEmail: userEmail,
        update: 'updateUserDetails',
        list: 'user',
      }

      // Submit feedback 
      await ctx.dispatch('updateList', userPayload);
    },
    async sendDiagnosticMail(){
        await $.post('sendMail');
    },
    async submit(ctx){
      ctx.commit('setSubmitting', true);
      let data;
      
      // Check if session has expired
      try {
        data = (await ctx.dispatch(`getFile`)).data;
      } 
      catch (e) {
        alert('Your session has expired. This window will reload. Please try again.');
        cookiejs.remove('authToken');
        location.reload();
      }

      //Failsafe if can't find the newly created Excel sheet yet
      const wait = (ms) => {
        return new Promise((resolve) => {
            setTimeout(()=>{
                resolve();
            }, ms)
        })
      }
      
      // Create Excel file for org if file is not found 
      if (!data.length){
        await $.post('excelFile');
        await wait(5000);
      }
      
      // Submit questionnaire answers, feedback and update user's submission status
      await ctx.dispatch('submitAnswers');
      await ctx.dispatch('submitFeedback');
      await ctx.dispatch('updateUserStatus');

      // If respondent is the last respondent 
      if(ctx.state.userDetails.isLastRespondent){
        await wait(5000);
        await ctx.dispatch('sendDiagnosticMail')
        
      }
      
      // Clear local storage and log out
      // localStorage.clear();
      localStorage.removeItem(ctx.state.userDetails.userEmail);
      await ctx.dispatch('logOut', '/');
      ctx.commit('setSubmitting', false);
      ctx.commit('setSubmitted', true);
      localStorage.setItem('submissionDoneMessage', 'true');
    }
  }
}
const store = new Vuex.Store(storeConfig);

export default store;
  