import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import store from '@/store/config.js'
import App from './App.vue'

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import VueStepProgressIndicator from "vue-step-progress-indicator";
import '@/assets/app.scss';
import router from '@/components/router.js';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ScreenSizeDetector from 'screen-size-detector';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin);
Vue.component('lottie-animation',LottieAnimation);
Vue.use(VueStepProgressIndicator);
Vue.component("v-select", vSelect);

Vue.mixin({
  methods: {
    globalScrollToTop: function () {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      });
    },
  },
})

new Vue({
  router,
  render: h => h(App),
  store,
  data() {
    return {
      width: null,
      inMobile: false,
      inTablet: false,
      inDesktop: false
    }
  },
  mounted() {
    // Check screen width and see what device user is on
    const checkScreen = () => {
      this.inMobile = screen.is.mobile;
      this.inTablet = screen.is.tablet;
      this.inDesktop = screen.is.desktop;
    }
    // Define widths
    const option = {
      onWidthChange: () => {
        this.width = screen.width;
        checkScreen();
      },
      widthDefinitions: {
        mobile: {
          min: 0,
          max: 767,
          inclusion: "[]",
        },
        tablet: {
          min: 768,
          max: 991,
          inclusion: "[]",
        },
        desktop: {
          min: 992,
          max: 99999,
          inclusion: "[]"
        }
      }
    }
    const screen = new ScreenSizeDetector(option);
    this.width = screen.width;
    checkScreen();
  
  }
}).$mount('#app')
