<template lang="pug">
        Domain(:data="data")           
</template>

<script>
import NavButtons from '@/components/navbuttons.vue';
import Domain from '@/components/domain.vue';
import Questions from '@/config/strategyLeadershipQuestions.js';

export default {
  name: 'Strategy&Leadership',

  data(){
     return{ 
         data: Questions()
    }
  },
  components: {
      NavButtons,
      Domain
  }
}
</script>