<template lang="pug">  
    #questions
      //- Progress bar
      //- b-progress(
      //-   class="sticky-top"
      //-   :value="$store.state.step"
      //-   :max="$store.state.steps.length"
      //-   animated
      //-   )
      
      //- Progress breadcrumbs 
      progressIndicator.no-print(class="progress-indicator")
      div
        router-view     
</template>

<script>
import overview from "@/screens/overview.vue";
import governance from "@/screens/governance.vue";
import progressIndicator from "@/components/progressIndicator.vue";
export default {
  name: 'Questions',
  components:{
    overview, 
    governance,
    progressIndicator
  },
}
</script>