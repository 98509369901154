<template lang="pug">
    #feedback.mx-0.mx-sm-5.py-0.py-sm-5
        div.feedback-main-div
            b-card.p-5
                div.d-flex.flex-column.align-items-center
                    div.w-80
                        NavButtons(:disabled="submitButtonDisabled")
                        h1.feedback-title Before you go, please share your feedback!
                        p.text-justify Congratulations! You have arrived at the final page of the OHFSS Self-Assessment. Your Diagnostic Lead will receive the Organisational Health Report containing the consolidated findings of your organisation’s self-assessment. Meanwhile, please share your feedback on the self-assessment. Please note that your submission will only be processed after you have given us your feedback. 
                        div(v-for="(question, index) in data" :key="index")
                            //- Title 
                            h5.py-2.underline.bold(v-if="question.title") {{question.title}}
                            
                            //- Question
                            p.feedback-question {{`${index+1}. ${question.question}`}}
                                span.red-text * Required field

                            h6
                                //- Radio selection
                                b-form-group(v-if="question.selection.length")
                                    template( v-if="typeof question.selection[0] === 'string'")
                                        b-form-radio(
                                            v-for="(selection, index) in question.selection" 
                                            :key="index"
                                            :name='question.name'
                                            :value="selection"
                                            v-model="feedbackQuestionObject[question.question].selectionAnswer"
                                        ) {{selection}}
                                    div.d-flex.justify-content-center(
                                        :class="{'flex-column': !$root.inDesktop}"
                                        v-else
                                        )
                                        div.d-flex.my-3.flex-wrap.justify-content-center( v-for="(selectionObject, index) in question.selection" :key="index")
                                                b-form-radio(
                                                    class="radio-image mx-1"
                                                    :name='question.name'
                                                    v-model="feedbackQuestionObject[question.question].selectionAnswer"
                                                    :value="selectionObject.value"
                                                ) 
                                                    .d-flex.flex-column.align-items-center
                                                        //b-img(fluid-grow :src="selectionObject.image" class="satisfaction-img")
                                                        lottie-animation(
                                                            :path="selectionObject.path"
                                                            :width="$root.inDesktop ? 150 : 100"
                                                        )
                                                        div.my-2 {{selectionObject.value}}   
                                //- Textarea
                                b-form-group(v-if="question.textarea")
                                    p.text-justify(v-if="question.question != question.textarea") {{question.textarea}}
                                        span(class='red-text')  * Required field
                                    b-form-textarea(
                                        type="text" 
                                        :rows="5"
                                        :max-rows="8"
                                        placeholder="Enter your response"
                                        v-model="feedbackQuestionObject[question.question].textareaAnswer" 
                                    )
                        div 
                            h1.feedback-thank Thank you!
                            p.text-center Please note that upon submission, your responses will be finalised. No more changes can be made.
                        //- Navigation buttons
                        NavButtons.mb-3(:disabled="submitButtonDisabled")
                        .d-flex.p-2
                            TechSupportLine
                        
</template>

<script>
import NavButtons from '@/components/navbuttons.vue';
import TechSupportLine from '@/components/technicalSupportLine.vue';
import data from '@/config/feedbackQuestions.js';

export default {
  name: 'Feedback',
  data(){
      return{
          data: data(),
          feedbackQuestionObject: this.$store.state.feedbackAnswers
      }
  },
  components:{
      NavButtons,
      TechSupportLine
  },
  watch: {
      feedbackQuestionObject: {
          handler(){
            // Watch feedback question object 
            this.data.forEach(q => {
                // Set answer, full answer depends on question
                // Selection only
                if(!q.textarea && q.selection.length){
                    this.feedbackQuestionObject[q.question]['fullAnswer'] = this.feedbackQuestionObject[q.question].selectionAnswer;
                }

                // Textarea only 
                if(q.textarea && !q.selection.length){
                    this.feedbackQuestionObject[q.question]['fullAnswer'] = this.feedbackQuestionObject[q.question].textareaAnswer.trim();
                }

                // Textarea + selection
                if(q.textarea && q.selection.length){
                    if(this.feedbackQuestionObject[q.question].selectionAnswer && this.feedbackQuestionObject[q.question].textareaAnswer){
                        this.feedbackQuestionObject[q.question]['fullAnswer'] = `${this.feedbackQuestionObject[q.question].selectionAnswer}. ${q.textareaHeader}${this.feedbackQuestionObject[q.question].textareaAnswer.trim()}`;
                    }
                }
            });

            this.$store.commit('setFeedbackAnswers', this.feedbackQuestionObject);
          },
          deep: true
      }
  },
  computed:{
       submitButtonDisabled(){
    
            return false;
        },
  }
}
</script>