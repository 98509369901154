const questions =  () =>{
    return {
        id:2,
        domain: "Finances",
        domainScore: '',
        domainRating: '',
        subdomain:[{
            title: "Funding Sustainability",
            description: "How the organisation establishes diversified and stable funding sources and builds capabilities and infrastructure for fundraising",
            rows: [{
                question: "The organisation has diversified and stable funding sources.",
                ratingDescriptors: [{
                    Limited: "The organisation has limited funding derived from similar sources with little potential for renewal. ",
                    Fair: "The organisation derives its funding from a few different sources, and has some potential for renewal.",
                    Good: "The organisation derives its funding from different sources which are mostly stable.",
                    Excellent: "The organisation has multiple funding sources that are well-diversified and possess a strong sense of stability."
                }],
                selected: null
            },
            {
                question: "The organisation has income-generating activities.",
                ratingDescriptors: [{
                    Limited: "There are limited income-generating activities conducted by the organisation.",
                    Fair: "A few income-generating activities are conducted by the organisation.",
                    Good: "The organisation has signature income-generating acitivities and conducts additional activities on an ad-hoc basis.",
                    Excellent: "The organisation conducts multiple income-generating activities consistently on a year-by-year basis."
                }],
                selected: null
            },
            {
                question: "The organisation has established the necessary skills and infrastructure to conduct fundraising.",
                ratingDescriptors: [{
                    Limited: "The organisation has limited skills and infrastructure to conduct fundraising.",
                    Fair: "The organisation has a basic understanding of the skills and infrastructure required for fundraising.",
                    Good: "The organisation has the required skills and infrastructure to conduct fundraising.",
                    Excellent: "The organisation possesses strong capabilities and a robust infrastructure for fundraising, that undergoes review periodically."
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        },
        {
            title: "Financial Management",
            description: "How the organisation ensures effective financial management with financial planning, systems and processes, and reserves management",
            rows: [{
                question: "The organisation conducts proper financial planning and analysis so as to monitor planned activities and demonstrate accountability.",
                ratingDescriptors: [{
                    Limited: "There is limited financial planning and analysis, resulting in the organisation being unable to monitor planned activities and promote accountability.",
                    Fair: "The organisation develops and conducts short-term financial planning and basic financial analysis, leading to accountability in most areas through monitoring of planned activities. ",
                    Good: "The organisation develops and conducts effective short-term and long-term financial planning and detailed financial analysis, leading to accountability in all areas through monitoring planned activities. ",
                    Excellent: "The organisation has a robust system in place to develop and conduct clear and well-defined financial plans and financial analysis, leading to an ability to easily monitor and track all planned activities, demonstrating exemplary levels of accountability."
                }],
                selected: null
            },
            {
                question: "The organisation aligns its procurement processes with organisational goals.",
                ratingDescriptors: [{
                    Limited: "There is limited or no clarity in the procurement process with no alignment to the organisational goals.",
                    Fair: "The procurement process has some alignment to the organisational goals but it is still vague.",
                    Good: "There is a clear procurement policy and guidelines, budgeting process and use of spend analysis to analyse purchases made to optimise benefits for the organisation. ",
                    Excellent: "There is a consistent promotion of accountability and transparency across the organisation with an extensive use of analytics to understand the spending behaviours of the organisation. Well defined governance and compliance rules are referenced to ensure strict adherence to them."
                }],
                selected: null
            },
            {
                question: "The organisation adequately plans for the application and management of its reserves.",
                ratingDescriptors: [{
                    Limited: "The organisation does not or rarely plans for the application and management of its reserves.",
                    Fair: "The organisation takes some effort in the application and management of its reserves on an ad hoc basis.",
                    Good: "The organisation takes an active effort in the effective application and management of its reserves.",
                    Excellent: "The organisation has a robust system in place for the sustained application and management of its reserves to ensure that it can sustain itself for a reasonable amount of time in the event it is unable to raise funds."
                }],
                selected: null
            },
            {
                question: "The organisation has a transparent accounting system and finance-related procedures.",
                ratingDescriptors: [{
                    Limited: "The organisation uses a manual approach for accounting and unclear finance-related procedures which leads to inconsistencies and confusion.",
                    Fair: "The organisation has a basic accounting system and finance-related procedures are documented for key processes.",
                    Good: "The organisation uses a well integrated accounting software that provides clear guidelines of all finance-related procedures being documented for all processes to promote transparency.",
                    Excellent: "The organisation has a comprehensive enterprise resource planning accounting system with clearly defined finance-related procedures for all processes to promote transparency and accountability and mandates regular accounting reviews to assess the collection and use of the budget."
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        },
        {
            title: "Process Readiness",
            description: "How the organisation ensures comprehensiveness in processes to meet financial needs",
            rows: [{
                question: "The organisation establishes process management that enables continuous improvement of how its finances function.",
                ratingDescriptors: [{
                    Limited: "The organisation has limited or no process management approach to track and monitor continuous improvement in its finance.",
                    Fair: "The organisation has taken some efforts to utilise a process management approach in some services and division to improve the organisation's finances. ",
                    Good: "The organisation has effective process management approach that drives periodic improvement of how its finances function.",
                    Excellent: "The organisation has a comprehensive process management system that drives continuous improvement of its finance function, with established platforms in place to collect feedback."
                }],
                selected: null
            },
            {
                question: "The organisation ensures that its current financial processes sufficiently meets its needs, such as through SOPs.",
                ratingDescriptors: [{
                    Limited: "The organisation has limited or no SOPS to drive performance of its basic finance needs.",
                    Fair: "The organisation has some SOPS for basic finance needs.",
                    Good: "The organisation has largely documented the SOPs for current processes that sufficiently meets most finance needs.",
                    Excellent: "The organisation has extensively documented the SOPs for current processes that sufficiently meets and anticipates its finance needs.  "
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        }]
    }
}

export default questions;