<template lang="pug">
  div.m-4
    h2 Assign participants from your organisation to complete this form.
    h6.text-justify If you are the assigned Diagnostic Lead of your Social Service Agency, please submit the selected participants of the OHFSS Online Self-Assessment here, to enable their access to the online assessment forms. Please note that the Agency Head and Diagnostic Lead (i.e. you) are mandatory participants of the online self-assessment. 
    b-button(variant="primary" size= 'lg' @click="next") Start
</template>

<script>
export default {
  name: 'Cover',
  data(){
    return {
      message: ""
    }
  },
  methods:{
    next(){
      this.$router.push({path: '/register/email'});
    },
  }
}
</script>