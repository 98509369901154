<template lang="pug">
    #register-users.d-flex.justify-content-center.align-items-center.mx-0.mx-sm-5.py-0.py-sm-5(style="min-height:100vh")
        
        //- Confirm modal upon clicking on confirm 
        b-modal(id="confirm" title="Are you sure?")
          p Are you sure the details entered are correct? 
          template(#modal-footer="{ok, cancel}")
              b-button(size="lg" variant="primary" @click="confirm") Yes, I am sure
              b-button(size="lg" @click="cancel") No


        b-card.p-1.w-100
          //- Header and subheader 
          h1.p-3.text-center.font-weight-bold Submit the Selected Participants of the OHFSS Online Self-Assessment Here
          h5.px-3.pb-3.text-center The Head of Agency and Diagnostic Lead are mandatory participants, beyond which you can add up to 8 more participants for your organisation. Please note all participants have to attempt the online self-assessment forms within 2 weeks.
          
          //- Show spinner when getting data 
          div.d-flex.justify-content-center.p-5(v-if="!getData")
            b-spinner(variant="primary")

          div.pb-4(v-if="getData && multipleOrgsCheck")
            h5.font-weight-bold Please select your organisation:
            b-form-select(
              :options="orgSelection" type="text" placeholder="Select organisation" label="Organisation" v-model="orgId")

          div(v-if="orgName")
            h4.pb-3 Your organisation: {{orgName}} 

          //- Show details after getting data 
          div(v-if="getData && orgId")
            b-row(v-if="!$root.inDesktop")
              p.w-100.font-weight-bold.text-center Click to expand each officer's details
            
            //- Show head of agency and diagnostic lead details on desktop view 
            div 
              h5.font-weight-bold Head of Agency & Diagnostic Lead
            div.w-100.border-bottom.border-top.py-2(v-if="$root.inDesktop")
              b-row.ml-2
                p.font-weight-bold.text-center Participant 1 (Head of Agency)
              b-row.m-1
                b-col.p-2.d-flex.align-items-center.w-100(md='10')
                  b-form-group.w-100.mb-0
                      p Name of Head of Agency
                        b-form-input(type="text" placeholder="Enter name" label="Name" v-model="headOfAgency.name" :state="headOfAgency.nameState")
                  b-form-group.w-100.mb-0
                    p Email address
                      b-form-input(type="text" placeholder="Enter email address" label="Work email address"  v-model="headOfAgency.newEmail" :state="headOfAgency.emailState")
                  b-form-group.w-100.mb-0
                    p Designation
                      v-select(
                        :clearable="false"
                        :reduce="reducer"
                        disabled taggable push-tags
                        :options="headLeadDesignationOptions"  placeholder="Enter designation" label="text" v-model="headOfAgency.designation")

            div.w-100.border-bottom.border-top.py-3(v-if="$root.inDesktop")
              b-row.ml-2
                h5.font-weight-bold.text-center Participant 2 (Diagnostic Lead)
              b-row.m-1
                b-col.p-2.d-flex.align-items-center.w-100(md='10')
                  b-form-group.w-100.mb-0
                      p Name of Diagnostic Lead
                        b-form-input(type="text" placeholder="Enter name" label="Name" v-model="diagnosticLead.name" :state="diagnosticLead.nameState")
                  b-form-group.w-100.mb-0
                    p Work Email Address
                      b-form-input(type="text" placeholder="Enter email address" label="Work email address"  v-model="diagnosticLead.newEmail" :state="diagnosticLead.emailState")
                  b-form-group.w-100.mb-0
                    p Designation
                      v-select(
                        :clearable="false"
                        :reduce="reducer"
                        taggable
                        push-tags
                        :options="diagnosticLeadDesignationOptions"  placeholder="Enter designation" label="text" v-model="diagnosticLead.designation" )

            //- Show head of agency and diagnostic lead details on mobile view 
            b-row(v-if="!$root.inDesktop")
              b-button.w-100.mb-3(v-b-toggle="'head'" variant = 'primary') Participant 1 (Head of Agency)
              b-collapse.w-100(:id="`head`" class="mt-2")
                b-card
                  b-col.d-flex.align-items-center.w-100.flex-column(md='12')
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Name
                        b-form-input.w-100(type="text" placeholder="Enter name" label="Name" v-model="headOfAgency.name" :state="headOfAgency.nameState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Email address
                        b-form-input.w-100(type="text" placeholder="Enter email address" label="Work email address"  v-model="headOfAgency.newEmail" :state="headOfAgency.emailState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Designation
                        v-select(
                          :clearable="false"
                        :reduce="reducer"
                          taggable push-tags
                          disabled :options="headLeadDesignationOptions"  placeholder="Enter designation" label="text" v-model="headOfAgency.designation")
            b-row(v-if="!$root.inDesktop")
              b-button.w-100.mb-3(v-b-toggle="'lead'" variant = 'primary') Participant 2 (Diagnostic Lead)
              b-collapse.w-100(:id="`lead`" class="mt-2")
                b-card
                  b-col.d-flex.align-items-center.w-100.flex-column(md='12')
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Name
                        b-form-input.w-100(type="text" placeholder="Enter name" label="Name" v-model="diagnosticLead.name" :state="diagnosticLead.nameState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Email address
                        b-form-input.w-100(type="text" placeholder="Enter email address" label="Work email address"  v-model="diagnosticLead.newEmail" :state="diagnosticLead.emailState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Designation
                        v-select(
                          :clearable="false" push-tags
                        :reduce="reducer"
                          taggable :options="diagnosticLeadDesignationOptions"  placeholder="Enter designation" label="text" v-model="diagnosticLead.designation")
            

            //- Desktop view 
            //- Existing participants (Only allow updates, not deletion)
            div(v-if="existingUsers.length") 
              h5.pt-5.font-weight-bold Previously added participants

            //- Show existing participants on mobile view 
            b-row.border-bottom.border-top.py-3(v-for="(user, index) in existingUsers" v-if="!$root.inDesktop && existingUsers.length" :key="'existing_' + index")
              b-button.w-100.mb-3(v-b-toggle="'existing_'+ index" variant = 'primary') Participant {{index + 3}}
              b-collapse.w-100(:id="`existing_${index}`" class="mt-2")
                b-card
                  b-col.d-flex.align-items-center.w-100.flex-column(md='12')
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Name
                        b-form-input.w-100(type="text" placeholder="Enter name" label="Name" v-model="user.name" :state="user.nameState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Email address
                        b-form-input.w-100(type="text" placeholder="Enter email address" label="Work email address"  v-model="user.newEmail" :state="user.emailState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Designation
                        v-select(
                          :clearable="false"
                        :reduce="reducer"
                          taggable push-tags
                          :options="designationOptions"  placeholder="Enter designation" label="text" v-model="user.designation")

            //- Show existing participants on desktop view 
            div.border-bottom.border-top.py-3(v-for="(user, index) in existingUsers" v-if="$root.inDesktop&& existingUsers.length" :key="'existing_' + index")
              b-row.ml-2
                h5.font-weight-bold Participant {{index+3}} 
              b-row.m-1
                b-col.p-2.d-flex.align-items-center.w-100(md='10')
                  b-form-group.w-100.mb-0
                    p Name of Participant
                      b-form-input(type="text" placeholder="Enter name" label="Name" v-model="user.name" :state="user.nameState")
                  b-form-group.w-100.mb-0
                    p Work Email Address
                      b-form-input(type="text" placeholder="Enter email address" label="Work email address"  v-model="user.newEmail" :state="user.emailState")
                  b-form-group.w-100.mb-0
                    p Designation
                      v-select(
                        :reduce="reducer"
                        taggable
                        :clearable="false"
                        push-tags
                        variant="danger"
                        :style="[{'border': !user.designation ? '1px solid  #dc3545 !important' : ''}]"
                        :options="designationOptions"  placeholder="Enter designation" label="text" v-model="user.designation")

            //- New participants
            div 
              h5.pt-5.font-weight-bold New participants
            
            //- New participants on mobile view 
            b-row.border-bottom.border-top.py-3(v-for="(user, index) in newUsers" v-if="!$root.inDesktop && newUsers.length" :key="'new_' + index")
              b-button.w-100.mb-3(v-b-toggle="'new_'+ index" variant = 'primary') Participant {{index + existingUsers.length + 3}}
              b-collapse.w-100(:id="`new_${index}`" class="mt-2")
                b-card
                  b-col.d-flex.align-items-center.w-100.flex-column(md='12')
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Name
                        b-form-input.w-100(type="text" placeholder="Enter name" label="Name" v-model="user.name" :state="user.nameState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Email address
                        b-form-input.w-100(type="text" placeholder="Enter email address" label="Work email address"  v-model="user.email" :state="user.emailState")
                    b-form-group.w-100.mb-0
                      p.font-weight-bold Designation
                        v-select(
                        :reduce="reducer"
                          taggable
                          :clearable="false"
                          push-tags
                          :style="[{'border': !user.designation ? '1px solid  #dc3545 !important' : ''}]"
                          :options="designationOptions" type="text" placeholder="Enter designation" label="text" v-model="user.designation")
                  b-col.d-flex.align-items-center
                    b-button(variant='danger' @click="deleteUser(index)") Delete
            
            //- New participants on desktop view
            div.border-bottom.border-top.py-3(v-for="(user, index) in newUsers" v-if="$root.inDesktop")
              b-row.ml-2
                h5.font-weight-bold Participant {{index + existingUsers.length + 3}} 
              b-row.m-1
                b-col.p-2.d-flex.align-items-center.w-100(md='10')
                  b-form-group.w-100.mb-0
                    p Name of Participant
                      b-form-input(type="text" placeholder="Enter name" label="Name" v-model="user.name" :state="user.nameState")
                  b-form-group.w-100.mb-0
                    p Work Email Address
                      b-form-input(type="text" placeholder="Enter email address" label="Work email address"  v-model="user.email" :state="user.emailState")
                  b-form-group.w-100.mb-0
                    p Designation
                      v-select(
                        :clearable="false"
                        taggable
                        push-tags
                        :reduce="reducer"
                        variant="danger"
                        :style="[{'border': !user.designation ? '1px solid  #dc3545 !important' : ''}]"
                        :options="designationOptions" placeholder="Enter designation" label="text" v-model="user.designation")
                b-col.d-flex.align-items-center( :class="['domain-subdomain-question', {'justify-content-center': $root.inDesktop}]")
                  b-button(variant='danger' @click="deleteUser(index)") Delete
            
            //- If no new partipants are added, show this 
            b-row.m-0.w-100.p-3.border-bottom.border-top(v-if="!newUsers.length && getData")
              h5.w-100.text-center.font-weight-bold You have not added any new participants yet. Click on 'Add Participant' to add a participant.  

            //- Add officer button 
            div.mt-3.d-flex.w-100
              div(id="addOfficerButton")
                b-button.m-1(variant='primary' @click='addNewUser' :disabled="addOfficerDisabled") Add Participant
                b-tooltip(target="addOfficerButton" v-if="addOfficerDisabled"  :placement="$root.inMobile ? 'top' : 'right'") You can only add up to a maximum of 10 participants.
              div(id="confirmButton")
                b-button.m-1(variant='primary' :disabled="confirmDisabled" @click="confirmAdd") Confirm
                b-tooltip(target="confirmButton" v-if="confirmDisabled && newUsers.length"  :placement="$root.inMobile ? 'top' : 'right'") Please ensure every field is filled
                b-tooltip(target="confirmButton" v-if="confirmDisabled && !newUsers.length" :placement="$root.inMobile ? 'top' : 'right'") There are no officers assigned yet. Click on "Add Participant" to assign a participant
            
            div.d-flex.w-100
              b-link.m-1.font-weight-bold(variant="primary" @click="logOut") Return to main page

</template>

<script>

export default {
  name: 'RegisterUsers',
  data(){
    return{
      newUsers:[],
      existingUsers:[], 
      headLeadDesignationOptions: [
        //{value: null, text: 'Please select an option', disabled: true},
        {value:'Head of Agency', text:"Head of Agency"},
        {value: 'Board Member', text:"Board Member"},
        //{value: 'Others', text:"Others"}
      ],
      diagnosticLeadDesignationOptions: [
        {value:'Diagnostic Lead', text:"Diagnostic Lead"},
      ],
      designationOptions: [
        //{value: null, text: 'Please select an option', disabled: true},
        {value: 'Board Member', text:"Board Member"},
        //{value: 'Others', text:"Others"}
      ],
      orgSelection:[],
      orgName: 'NCSS',
      orgId: 1,
      diagnosticLead: {id: null, name: '', designation: 'Diagnostic Lead', newEmail: '', existingEmail: '', emailState: null, nameState: null},
      diagnosticLeadCheck: false,
      headOfAgency: {id: null, name: '', designation: 'Head of Agency', newEmail: '', existingEmail: '', emailState: null, nameState: null},
      headOfAgencyCheck: false, 
      getData: false,
      multipleOrgsCheck: false
    }
  },
  async mounted(){
     try{
       // Get organisation name and organisation ID 
      const {data} = await this.$store.dispatch('getList',  {list: 'org'});

      console.log(data)
      if(data.length === 0){
        const orgData = data[0].fields;
        this.orgName = orgData.Title;
        this.orgId = orgData.id;
      }else{
        this.multipleOrgsCheck = true;
        // this.orgs = data;
        this.orgSelection.push( {value: null, text: 'Please select an option', disabled: true});
        for(let orgObj of data){
          this.orgSelection.push({text: orgObj.fields.Title, value: orgObj.fields.id});
        }

        this.getData = true;
      }
     }
      catch(e){
        this.getData = true;
        /*
        alert('Your session has expired. This window will reload. Please try again.');
        await this.$store.dispatch('logOut');
        location.reload();
        return; 
        */
      }
    
 
  },
  methods:{
    reducer(opt){
      return{
        value: opt.text,
        text:opt.text
      }
    },
    confirmAdd(){
      this.$bvModal.show('confirm');
    },
    addNewUser(){
      // Add a new user to new users array 
      this.newUsers.push({name: '', designation: null, email: '', emailState: null, nameState: null});
    },
    validateEmail(email){
      // No longer needed if there are no email inputs
      // Check if email is valid
      const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(email){
          return email.match(emailFormat) ? true : false;      
      }
      // No email (no value to check) -> return null 
      return null;
    },
    async confirm(){
      this.$router.push('/register');
      this.$store.commit('setSubmitting', true);
      this.$store.commit('setSubmitting', true);
        setTimeout(() => {
            this.$store.commit('setSubmitting', false);
            this.$store.commit('setSubmitted', true);
          }, 5000);
    },
    deleteUser(index){
      // Delete a new users 
      this.newUsers.splice(index, 1);
    },
    nameState(index, state, list){
      // Set name input field state for existing users and new users
      switch(list){
        case 'existingUser':
          this.existingUsers[index].nameState = state;
          break;
        case 'newUser':
          this.newUsers[index].nameState = state;
          break;
      }
    
    },
    emailState(index, state, list){
      // Set email input state for existing users and new users 
      switch(list){
        case 'existingUser':
          this.existingUsers[index].emailState = state;
          break;
        case 'newUser':
          this.newUsers[index].emailState = state;
          break;
      }
    },
    setDiagnosticLeadState(nameState, emailState){
      // Set diagnostic lead name state and email input field state
      this.diagnosticLead.nameState = nameState;
      this.diagnosticLead.emailState = emailState;
    },
    setheadOfAgencyState(nameState, emailState){
      // Set head of agency name state and email state 
      this.headOfAgency.nameState = nameState;
      this.headOfAgency.emailState = emailState;
    },
  },
  watch:{
    orgId: async function(val){
      this.getData = false;
      // Get existing participants  
      const usersData = await this.$store.dispatch('getList',  {list: 'user', where:{orgLookupId: val}});

      // Upon change of org id (select different org on dropdown), reset all variables
      this.diagnosticLeadCheck = false;
      this.headOfAgencyCheck = false;
      this.diagnosticLead = {id: null, name: '', designation: 'Diagnostic Lead', newEmail: '', existingEmail: '', emailState: null, nameState: null};
      this.headOfAgency = {id: null, name: '', designation: 'Head of Agency', newEmail: '', existingEmail: '', emailState: null, nameState: null};
      this.existingUsers = [];

      if(usersData.data.length){
        // Only one Diagnostic Lead and one Head of Agency
        for(let userObj of usersData.data){
          // There is a diagnostic lead
          console.log(userObj);
          if(userObj.fields.diagnosticLead){
            this.diagnosticLead.name = userObj.fields.Title;
            this.diagnosticLead.existingEmail = userObj.fields.userEmail;
            this.diagnosticLead.newEmail = userObj.fields.userEmail
            this.diagnosticLeadCheck = true;
            this.diagnosticLead.id = userObj.fields.id;
          }
          // There is a head of agency 
          else if(userObj.fields.designation == "Head of Agency"){
            this.headOfAgency.name = userObj.fields.Title;
            this.headOfAgency.newEmail = userObj.fields.userEmail;
            this.headOfAgency.existingEmail = userObj.fields.userEmail;
            this.headOfAgencyCheck = true;
            this.headOfAgency.id = userObj.fields.id;
          }
          else{
            this.existingUsers.push({name: userObj.fields.Title, designation: userObj.fields.designation, newEmail: userObj.fields.userEmail, existingEmail: userObj.fields.userEmail, emailState: null, nameState: null});
          }
         }
        }

        this.getData = true;
    }
  }, 
  computed:{

    addOfficerDisabled(){
      if(this.newUsers.length + this.existingUsers.length >= 8){
        return true;
      }else{
        return false;
      }
    },
    confirmDisabled(){
      let i = 0;
      // eslint-disable-next-line
      let disabled = false;

      if(this.orgSelection.length && !this.orgId){
        disabled = true; 
      }

      if(!this.diagnosticLead.name || !this.validateEmail(this.diagnosticLead.newEmail) || !this.headOfAgency.name || !this.validateEmail(this.headOfAgency.newEmail)){
        disabled = true;
      }

      this.setDiagnosticLeadState(Boolean(this.diagnosticLead.name), this.validateEmail(this.diagnosticLead.newEmail));
      this.setheadOfAgencyState(Boolean(this.headOfAgency.name), this.validateEmail(this.headOfAgency.newEmail));

      for(let userObj of this.existingUsers){
        let nameState = true;
        let emailState = true;
        if(!userObj.name){
          disabled = true;
          nameState= false;
        }

        if(!this.validateEmail(userObj.newEmail)){
          disabled = true;
          emailState= false;
        }

        if(!userObj.designation){
          disabled=true;
        }

        this.nameState(i, nameState, 'existingUser');
        this.emailState(i, emailState, 'existingUser');
        i++;
      }
      
      i = 0;
      // Loop through new users, check name and email
      for(let userObj of this.newUsers){
        let nameState = true;
        let emailState = true;
        if(!userObj.name){
          nameState= false;
        }

        if(!this.validateEmail(userObj.email)){
          emailState= false;
        }

        this.nameState(i, nameState);
        this.emailState(i, emailState);
        i++;
      }
      
      return false;
    }
  }
}
</script>