const questions  = () =>{return {
    id:5,
    domain: "Digitalisation",
    domainScore: '',
    domainRating: '',
    subdomain:[{
        title: "Vision & Alignment",
        description: "How important digitisation is to the organisation, and how digitalisation projects are prioritised",
        rows: [{
            question: "The organisation prioritises digitalisation, and has a framework to prioritise such projects.",
            ratingDescriptors: [{
                Limited: "The organisation knows of the impact of digitalisation but does not have a digital strategy. Digitalisation projects take place in an ad hoc manner and their performance is not monitored.",
                Fair: "Digital strategies, ideation and pilots occur in several departments or functions. These departments or functions have their own framework for prioritising digitalisation projects based on trends and impact.",
                Good: "The organisation has a target state of digitalisation and this is articulated to all staff. Ideation and pilots appear to be systematically done by departments or functions. There is a common framework to evaluate digitalisation projects based on specific measurements.",
                Excellent: "Digitalisation is top priority and an integral part of the organisation's vision. A formal process to prioritise, evaluate and measure the performance of digitalisation projects is systematically and consistently practised by all departments or functions."
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "End-to-End (E2E) Stakeholder Journeys",
        description: "How the organisation designs its stakeholder journeys to provide the best experience through digitalisation",
        rows: [{
            question: "The organisation has a process in place to design its stakeholder journey to provide the best experience through digitalisation.",
            ratingDescriptors: [{
                Limited: "The organisation does not map stakeholder journeys from end to end. Occasionally, the organisation analyses interactions or uses surveys to measure stakeholder experience.",
                Fair: "Within specific functions, the organisation uses human-centred design to map stakeholder interactions across touchpoints, channels and services.",
                Good: "The organisation maps stakeholder journeys across functions or programmes, and has a comprehensive stakeholder experience measurement framework to implement and re-iterate user experience design.",
                Excellent: "The organisation monitors and measures stakeholder experience across all channels and services - over multiple years - to drive deep engagement and relationships. This extends to interactions outside of its systems."
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Service Delivery",
        description: "How the organisation optimises service delivery with digital tools and technology",
        rows: [{
            question: "The organisation uses digital tools and technology to optimise its service delivery.",
            ratingDescriptors: [{
                Limited: "Services are mostly delivered in a manual way; touchpoints are mainly face-to-face (physical). There is no self-service channels available.",
                Fair: "There is a mix of physical and digital service delivery channels. The organisation is starting to optimise service delivery by integrating physical and digital channels, through pilots.",
                Good: "Some level of multi-channel intregration is achieved. The organisation is starting to pilot emerging front-end technology like AI, chatbots, AR/VR.",
                Excellent: "Services are delivered via the most effective mix of physical and digital channels or touchpoints. Digital tools (including emerging technology like AI and AR/VR) are fully harnessed for service delivery."
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Corporate/Support Functions",
        description: "How the organisation uses technology to digitalise and automate corporate and support functions",
        rows: [{
            question: "The organisation uses technology to digitalise and automate corporate and support functions.",
            ratingDescriptors: [{
                Limited: "Many administrative tasks remain heavily manual. There is a low degree of digitalisation in corporate and support functions. ",
                Fair: "Pilot projects involving document/workflow management, RPA, etc. are used to explore automation opportunities. Senior Management places emphasis on automation.",
                Good: "Successful case studies from several corporate functions are used to drive more digital transformation. Corporate processes are systematically reviewed and streamlined to leverage automation.",
                Excellent: "Most corporate and support functions are digitalised - including the use of RPA or AI to reduce the need for human intervention and to minimise errors. Management relies on real-time data and analysis for decision-making."
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Skills & People",
        description: "How the organisation hires digital talents (including employees and volunteers) and develops required digital skills",
        rows: [{
            question: "The organisation hires digital talents (including both employees and volunteers) and develops the required digital skills.",
            ratingDescriptors: [{
                Limited: "There is no intention to hire digital talent specifically. Training plans do not include digital skills.",
                Fair: "The organisation wants to hire digital talent but faces challenges. Efforts have started to look at relevant digital skills for our training plans. Some employees/volunteers have digital skills.",
                Good: "The organisation intentionally hires people with specific digital skills. Training plans ensure most, if not all, employees/volunteers are equipped with necessary digital capabilities.",
                Excellent: 'There has been success in hiring and retaining digital talent. Almost everyone in the organisation has the necessary digital capabilities. Employees/volunteers see the organisation as a "tech organisation".'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Data Strategy ",
        description: "How the organisation capitalises on the value of data",
        rows: [{
            question: "The organisation capitalises on data for its services and programmes.",
            ratingDescriptors: [{
                Limited: "There is no intentional collection of data. The organiation does not know how to make use of data to create greater impact or value.",
                Fair: "Some departments or functions are starting to analyse data, using insights garnered to improve their services on an ad hoc basis.",
                Good: "There is a data strategy in place that covers the collection, fusion, access and distribution, and exploitation of data. Employees have the proficiency to capitalise on data in their work. Systems/technologies are in place to facilitate this value creation.",
                Excellent: 'Data is recognised and exploited as a key asset. No service or programme is implemented without the use of data. Employees are comfortable exchanging data (within governance frameworks). Budget and resources are invested in AI etc. to maximise the value of data.'
            }],
            selected: null
        },
        {
            question: "The organisation complies with the Personal Data Protection Act and has a robust data protection management programme.",
            ratingDescriptors: [{
                Limited: "The organisation has not appointed a Data Protection Officer (DPO) and does not comply with the Personal Data Protection Act (PDPA).",
                Fair: "The organisation has appointed a DPO, and is taking stock of data protection policies and processes and identifying existing gaps.",
                Good: "The organisation has taken stock of data protection policies and processes and identified existing gaps. Employees are educated on the PDPA and the importance of protecting data.",
                Excellent: 'The organisation is fully compliant with the PDPA and has a robust data protection management programme. It is working towards, or has achieved, the Data Protection Trustmark (DPTM) certification.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    }]
}
}

export default questions;