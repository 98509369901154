import startLeadership from '@/assets/images/stratLeadership.png';
import governance from '@/assets/images/governance.png';
import commsPartnerships from '@/assets/images/commsPartnerships.png';
import finances from '@/assets/images/finances.png';
import people from '@/assets/images/people.png';
import userCentric from '@/assets/images/userCentric.png';
import digitalisation from '@/assets/images/digitalisation.png';

const domain =[
    {
        domain: 'Strategy & Leadership',
        subdomain:['Mission & Vision', 'Strategy Development', 'Strategy Implementation', 'Organisation Structure', 'Organisation Culture'],
        image: startLeadership
    },
    {
        domain: 'Governance',
        subdomain:['Board Compliance & Composition', 'Role of Board', 'Risk Management'],
        image: governance
    },
    {
        domain: 'People',
        subdomain:['People & Succession Planning', 'Recruitment', 'Staff Engagement & Well-Being', 'Learning & Development', 'Performance Management & Recognition', 'Volunteer Management', 'Process Readiness'],
        image: people
    },
    {
        domain: 'Finances',
        subdomain:['Funding Sustainability', 'Financial Management', 'Process Readiness'],
        image: finances
    },
    {
        domain: 'Digitalisation',
        subdomain:['Vision & Alignment', 'End-to-End (E2E) Stakeholder Journeys', 'Service Delivery', 'Corporate/ Support Functions', 'Skills & People', 'Data Strategy'],
        image: digitalisation
    },
    {
        domain: 'Communications & Partnerships',
        subdomain:['External Communication & Branding', 'Partnerships & Alliances', 'Community Involvement', 'Process Readiness'],
        image: commsPartnerships
    },
    {
        domain: 'User-Centric Services',
        subdomain:['User Service Design', 'Service Effectiveness Measurement', 'Knowledge & Research', 'Process Readiness'],
        image: userCentric
    }
]

export default domain; 