<template lang="pug">
    div
        Domain(:data="data")             
</template>

<script>
import Domain from '@/components/domain.vue';
import Questions from '@/config/processDigitalisationQuestions.js';

export default {
  name: 'ProcessDigitalisation',

  data(){
     return{ 
         data: Questions()
    }
  },
  components: {
      Domain
  }
}
</script>