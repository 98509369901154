const questions =  () =>{ return {
    id:7,
    domain: "User-Centric Services",
    domainScore: '',
    domainRating: '',
    subdomain:[{
        title: "User Service Design",
        description: "How the organisation designs services based on its strategic direction and identified user needs and gaps",
        rows: [{
            question: "The organisation offers services that integrate and align with its mission and organisational goals.",
            ratingDescriptors: [{
                Limited: "The services offered by the organisation are ad hoc in nature and not well integrated and aligned with its mission and organisational goals.",
                Fair: "Some of the organisation's services integrate and align with its mission and organisational goals.",
                Good: "Most of the organisation's services integrate and align with its mission and organisational goals.",
                Excellent: "All of the organisation's services integrate and align with its mission and organisational goals with a clear value proposition to prospective clients."
            }],
            selected: null
        },
        {
            question: "The organisation defines and identifies its targeted users for its service delivery.",
            ratingDescriptors: [{
                Limited: "The organisation does not have an identified list of targeted users for its service delivery.",
                Fair: "The organisation is beginning to define and identify its target users for its service delivery.",
                Good: "The organisation has a good understanding of its targeted users for its service delivery.",
                Excellent: 'The organisation is forward looking and anticipates the needs of its targeted users for its service delivery.'
            }],
            selected: null
        },
        {
            question: "The organisation designs new programmes based on identified user needs and related gaps in its service delivery.",
            ratingDescriptors: [{
                Limited: "There is little knowledge of user needs and service gaps, so new programmes are rarely introduced.",
                Fair: "There is basic understanding of user needs and service gaps so new programmes are designed on an ad-hoc basis.",
                Good: "There is a broad understanding of user needs and service gaps so new programmes are purposefully designed and introduced in a timely manner.",
                Excellent: 'Future user needs and service gaps are researched and developed in response to anticipated trends so new programmes are designed in advance of these needs.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Service Effectiveness Measurement",
        description: "How the organisation measures performance and outcomes for its services",
        rows: [{
            question: "The organisation defines planned outcomes for its services, and assesses the impact achieved for its targeted users.",
            ratingDescriptors: [{
                Limited: "The organisation does not have defined measures to track its service performance hence limited or no tracking takes place.",
                Fair: "The organisation is beginning to define its measures and track service performance systematically.",
                Good: "The organisation has a defined set of measures and these are consistently tracked and measured to assess service performance.",
                Excellent: "There is a robust and well-defined system to measure and track its service performance that serves as a basis for performance review and effective gap identification. "
            }],
            selected: null
        },
        {
            question: "The organisation systematically measures and tracks service outcomes.",
            ratingDescriptors: [{
                Limited: "The organisation does not have planned outcomes for its services, and there are no efforts to assess the impact achieved for its targeted users.",
                Fair: "The organisation has planned outcomes for some of its services, and assesses the impact achieved for its target users on an ad-hoc basis.",
                Good: "The organisation defines planned outcome for most of its services, and assesses the impact achieved for its target users consistently.",
                Excellent: 'The organisation defines planned outcomes for all its services, and improves its services based on the robust assessment of the impact achieved for its target users.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Knowledge & Research",
        description: "How the organisation collects, manages and analyses data on service performance to inform planning and decision-making",
        rows: [{
            question: "The organisation incorporates the analysis of its service outcomes and user feedback to adjust its services.",
            ratingDescriptors: [{
                Limited: "The organisation rarely adjusts its services, with limited knowledge on addressing gaps in service performance.",
                Fair: "The organisation adjusts its services on an ad-hoc basis as basic analysis of service performance and user feedback is undertaken at the organisation.",
                Good: "The organisation adjusts it services in a timely manner based on the detailed analysis of its service performance and user feedback.",
                Excellent: "The organisation conducts high levels of analysis for its service performance and user feedback with expectations set for future service delivery."
            }],
            selected: null
        },
        {
            question: "The organisation manages its data and knowledge effectively.",
            ratingDescriptors: [{
                Limited: "Data and information is collected and stored on an ad-hoc basis.",
                Fair: "Data and information is collected and stored manually, or on shared drives.",
                Good: "All data and information is collected and stored systematically in a consolidated platform for ease of data extraction.",
                Excellent: "All data and information is collected and managed in a consolidated platform to facilitate analytical and predictive analysis, decision-making."
            }],
            selected: null
        },
        {
            question: "The organisation conducts research relevant to current and potential service users.",
            ratingDescriptors: [{
                Limited: "The organisation does not conduct research relevant to current and potential service users.",
                Fair: "The organisation conducts research in an ad hoc fashion.",
                Good: "The organisation conducts research regularly, however, research is not an integral part of organisational strategy or processes.",
                Excellent: "The organisation conducts research regularly, and research is an integral part of the organisational strategy or processes."
            }],
                selected: null
        },
        {
            question: "The organisation incorporates research findings relevant to current/potential service users into its services.",
            ratingDescriptors: [{
                Limited: "The organisation rarely adjusts its services, and there is limited knowledge on needs gaps or service improvement.",
                Fair: "The organisation adjusts its services on an ad-hoc basis as basic analysis to address needs gaps and/or improve services is undertaken at the organisation.",
                Good: "The organisation adjusts it services in a timely manner based on the detailed analysis to address needs gaps and/or improve services is undertaken at the organisation.",
                Excellent: "The organisation conducts high levels of analysis to address needs gaps and/or improve services with expectations set for future service delivery."
            }],
                selected: null
        },
        {
            question: "The organisation collaborates with other partners e.g. social service agencies (SSAs), Institutes of Higher Learning (IHLs) and other sector stakeholders on research/evaluation of its programmes.",
            ratingDescriptors: [{
                Limited: "The organisation does not collaborate with any partners on evaluation.",
                Fair: "The organisation collaborates with sector partners on evaluation in a limited manner.",
                Good: "The organisation is an active collaborator with sector partners on evaluation.",
                Excellent: "The organisation takes the lead in collaborating with sector partners on evaluation."
            }],
                selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Process Readiness",
        description: "How the organisation ensures comprehensiveness in processes to meet service needs",
        rows: [{
            question: "The organisation establishes process management that enables continuous improvement of how its services function.",
            ratingDescriptors: [{
                Limited: "The organisation has limited or no process management approach to track and monitor continuous improvement of services.",
                Fair: "The organisation has taken some efforts to utilise a process management approach in some programmes or services.",
                Good: "The organisation has effective process management approach that drives periodic improvement of all its programmes or services.",
                Excellent: "The organisation has a comprehensive process management system that drives continuous improvement of its programmes and services, with established platforms in place to collect feedback."
            }],
            selected: null
        },
        {
        question: "The organisation ensures that its current processes sufficiently meet the needs of its programmes and services, such as through SOPs.",
        ratingDescriptors: [{
            Limited: "The organisation has limited or no SOPS to drive performance of basic services and programmes.",
            Fair: "The organisation has some SOPS in place with processes to meet the needs of some of its services and programmes.",
            Good: "The organisation has largely documented the SOPs for processes that meet most of its programme and service needs.",
            Excellent: "The organisation has extensively documented the SOPs for current processes that sufficiently meet and anticipate programme and service needs.  "
        }],
        selected: null
    }],
        subdomainScore: '',
        subdomainRating: ''
    }]
}
}

export default questions;