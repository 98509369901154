const questions  = () =>{ return {
    id:6,
    domain: "Strategy & Leadership",
    domainScore: '',
    domainRating: '',
    subdomain:[{
        title: "Mission & Vision",
        description: "How the leadership leverages on the Mission & Vision to guide the organisation to achieve and sustain excellence",
        rows: [{
            question: "The organisation has a documented Mission and Vision which drives its strategy and plans.",
            ratingDescriptors: [{
                Limited: "The organisation's Mission and Vision has little or no connection to its strategy and plans.",
                Fair: "The organisation's strategy and plans have basic alignment to its Mission and Vision.",
                Good: "The organisation's Mission and Vision have a clear link to its strategy and plan.",
                Excellent: "The organisation's Mission and Vision are the main drivers of its strategy and plans that guide all decisions at the organisation."
            }],
            selected: null
        },
        {
            question: "The leadership is committed to the organisation's Mission, Vision and Values.",
            ratingDescriptors: [{
                Limited: "The Mission and Vision are undefined.",
                Fair: "The Mission and Vision are beginning to take shape but implementation is ad hoc in nature and there is limited commitment by the leadership team.",
                Good: "The Mission and Vision are defined and the leadership has a strong commitment to ensure that they are well-integrated with organisational needs.",
                Excellent: 'The Mission and Vision are continuously innovative, forward-looking and guide all decisions of the leadership.'
            }],
            selected: null
        },
        {
            question: "The leadership communicates the organisation's Mission, Vision and Values to stakeholders to guide the organisation.",
            ratingDescriptors: [{
                Limited: "The Mission and Vision are not mentioned in the leadership's communication.",
                Fair: "There are some references of the Mission and Vision in the leadership’s communication.",
                Good: "The Mission and Vision are communicated most of the time and are well integrated into the leadership’s communication.",
                Excellent: 'The Mission and Vision are consistently communicated in all leadership communication and employee buy-in is continously pursued.'
            }],
            selected: null
        },
        {
            question: "The leadership motivates and inspires team spirit in employees",
            ratingDescriptors: [{
                Limited: "The leadership takes an ad hoc approach to motivate and inspire staff.",
                Fair: "The leadership is taking steps to motivate and inspire staff, although the approach is not consistent.",
                Good: "The leadership engages in efforts to motivate and inspire staff and this is taken up consistently across most functions.",
                Excellent: 'The leadership embodies the organisational values and instils team spirit, by proactively engaging all functions of the organisation to enhance feelings of pride and mutual loyalty.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Strategy Development",
        description: "How the organisation uses emerging scenarios and involves key stakeholders to develop various strategic plans",
        rows: [{
            question: "The organisation develops its strategy and plans based on emerging scenarios.",
            ratingDescriptors: [{
                Limited: "The strategy is undefined with no emerging scenarios being taken into account.",
                Fair: "The strategy is developed based on the immediate scenarios that will impact the organisation.",
                Good: "The strategy is progressive and well-integrated with the organisational needs and most emerging scenarios.",
                Excellent: "The strategy is systematically developed based on the external environment, thoroughly considers emerging scenarios, future plans and other factors."
            }],
            selected: null
        },
        {
            question: "The organisation has mid-term (2-3 years) and long-term (4-5 years) strategies to achieve its organisational goals.",
            ratingDescriptors: [{
                Limited: "The strategy development process is reactive with no mid-term or long-term plans.",
                Fair: "The strategy development process produces a plan for a few functional areas of the organisation with no clear overall strategy for the long term.",
                Good: "The strategy development process produces a clear plan that steers the organisation and all of its functions in the intended direction with a detailed timeline of work plans for the long term.",
                Excellent: "The strategy development process produces a robust plan that addresses the existing needs of the organisation as well as prepares the organisation to address emerging trends with well calibrated timeline and resources for the long term."
            }],
            selected: null
        },
        {
            question: "The organisation involves its leadership and key employees in the strategy development and planning process.",
            ratingDescriptors: [{
                Limited: "Key stakeholders have limited involvement in the strategy development and planning process.",
                Fair: "Key stakeholders are involved in the strategy development process on an ad hoc basis.",
                Good: "Key stakeholders are regularly involved in the strategy development process.",
                Excellent: "Key stakeholders are actively involved in the strategy development process with established platforms to provide continuous feedback and steer."
            }],
            selected: null
        }]
    },
    {
        title: "Strategy Implementation",
        description: "How the organisation creates well-defined strategic action plans and communicates them to key stakeholders",
        rows: [{
            question: "The organisation translates its strategy into action plans to achieve organisational goals.",
            ratingDescriptors: [{
                Limited: "The action plans for the organisation's strategies are undefined.",
                Fair: "The action plans for the organisation's strategies are beginning to be defined.",
                Good: "The long and short term strategies are defined with action plans containing specific steps and actions to be taken.",
                Excellent: "The long and short term strategies are progressive with well-defined action plans that contains clear objectives, involved stakeholders, actions and deadlines to effect strategy implementation across defined timelines of the organisation's strategic plans."
            }],
            selected: null
        },
        {
            question: "The organisation ensures that all the employees and stakeholders within the organisation are aware of the goals and what they need to do to achieve them.",
            ratingDescriptors: [{
                Limited: "Key stakeholders have limited knowledge of the organisational goals.",
                Fair: "Key stakeholders have basic knowledge of organisational goals.",
                Good: "Key stakeholders are aware of organisational goals and are engaged on key platforms to drive the organisation's performance.",
                Excellent: "All key stakeholders understand the organisational goals and are continuously engaged on multiple formal and informal platforms to drive the organisation's performance."
            }],
            selected: null
        }]
    },
    {
        title: "Organisation Structure",
        description: "How the organisation structure anticipates staffing needs ahead of time and clearly defines job roles",
        rows: [{
            question: "The organisation structure is aligned with its strategic priorities and suitably staffed.",
            ratingDescriptors: [{
                Limited: "The organisation structure and HR needs, including recruitment, are not anticipated ahead of time and do not have a defined link to strategic priorities and organisational values.",
                Fair: "The organisation structure and HR needs, including recruitment, are created with some basic alignment to the strategic priorities and organisational values.",
                Good: "The organisation structure meets the needs of its strategic priorities with sufficient staff to support most functions.",
                Excellent: "The organisation anticipates staffing needs ahead of time and has an effective organisation structure that is strongly aligned with its strategic priorities and organisational values."
            }],
            selected: null
        },
        {
            question: "The organisation structure effectively outlines the roles of functions performed in the organisation.",
            ratingDescriptors: [{
                Limited: "The job roles are unclear and there is no definition of what the intended performance is.",
                Fair: "The job roles have some definition with a basic understanding of job responsibilities and the intended performance level for each service or function.",
                Good: "The job roles are defined with detailed responsibilities and the intended performance level for each service or function. ",
                Excellent: "The organisation structure effectively outlines the job roles and has a clear definition of what high performance is for each service or function. "
            }],
            selected: null
        }]
    },
    {
        title: "Organisation Culture",
        description: "How the leadership develops a culture that is consistent with its values, and encourages learning, innovation as well as organisational change",
        rows: [{
            question: "The leadership develops a culture with collective beliefs and values.",
            ratingDescriptors: [{
                Limited: "The leadership has a limited understanding of the culture and its alignment to the organisation's Mission and Vision.",
                Fair: "The leadership is beginning to understand how to develop a culture that supports the organisation's Mission, Vision and Values to drive growth.",
                Good: "The leadership develops an effective culture across most functions that is in-line with the organisation's Mission, Vision and Values to drive growth.",
                Excellent: "The leadership has developed a continuously innovative culture to drive growth in the organisation and is strongly aligned with the Mission, Vision and Values."
            }],
            selected: null
        },
        {
            question: "The leadership develops an organisation culture that encourages learning and innovation.",
            ratingDescriptors: [{
                Limited: "Learning and innovation is an ad hoc pursuit by the leadership of the organisation.",
                Fair: "A culture of learning and innovation is beginning to take shape at the organisation, although the approach by the leadership is still vague.",
                Good: "Learning and innovation is consistently encouraged by the leadership at the organisation.",
                Excellent: "Learning and innovation is embedded within the organisation’s culture by the leadership through established platforms and feedback channels."
            }],
            selected: null
        },
        {
            question: "The leadership develops an organisation culture that embraces organisational change for sustainability.",
            ratingDescriptors: [{
                Limited: "There are limited initiatives and actions by the leadership in driving necessary change for sustainability.",
                Fair: "There are ad hoc efforts taken by the leadership in developing and/or communicating change management plans for sustainability.",
                Good: "There are well-defined efforts taken by the leadership in developing and communicating change management plans for sustainability through key platforms (e.g. townhalls).",
                Excellent: "There are extensive efforts taken across the leadership of the organisation to create an organisation that continuously analyses and identifies change for sustainability, then develops and communicates change management plans through mulitple platforms (e.g. townhalls, conferences, learning festivals)."
            }],
            selected: null
        }]
    }]
}
}

export default questions;