<template lang="pug">
    #redirect.mx-0.mx-sm-5.py-0.py-sm-5
      b-card.p-5
          //- Print note to tell user to set printing settings 
          b-modal.no-print(id="printNote" title="Note" v-model="printNote")
            p Please check the 'Background graphics' checkbox under 'More Settings' as shown in the image below for better printing format. For the colored version, please select 'Color' under the Color option as shown in the image after checking 'Background graphics'.
            b-img(:src="printImg")
            b-img(:src="printColorImg")
            template(#modal-footer="{ok}")
                b-button(size="lg" variant="primary" @click="print" ) Proceed

          div.d-flex.flex-column.align-items-center
              div.w-80
                //- Navigation buttons 
                NavButtons.no-print.pb-4

                //- Print button and export to excel button 
                div.d-flex.no-print
                  b-link.ml-auto(@click="printAlert") Print or Save as PDF
                div.d-flex.no-print
                  b-link.ml-auto(@click="exportExcel") Export to Excel

                //- Scoring summary report 
                h1.font-weight-bold.text-center Individual Scoring Summary Report
                p.text-justify.no-print Here is the summary of your organisation’s domain and sub-domain scores, based on your responses to the 77 statements in the OHFSS questionnaire. At the end of the summary, you will find the Organisational Health Index (OHI). You can save or print a copy of this summary report for your own reference, by clicking on the button, “Print or Save as PDF”, on the top right corner of this page.  You can also export your individual summary report to Excel by clicking on “Export to Excel”, which is found on the right corner of the page too.
                p.text-justify.no-print Please note that this is a summary of your responses. The consolidated Organisational Health Report, based on all respondents from your organisation, will be sent to your Diagnostic Lead separately. 
                
                //- Table, desktop view
                div(v-if='$root.inDesktop')
                  //- Table header
                  b-row.m-0.border.border-bottom-0(align-v="stretch" style="background-color: #1f487e; color: white") 
                    b-col.pt-2.d-flex.align-items-center.justify-content-center.border-right(cols="3")
                      p.text-center.font-weight-bold Domain Name 
                    b-col.pt-2.d-flex.align-items-center.justify-content-center.border-right(cols="3")
                      p.text-center.font-weight-bold Sub-domain Name 
                    b-col.pt-2.d-flex.align-items-center.justify-content-center.border-right(cols="1")
                      p.text-center.font-weight-bold Sub-domain Score (%)
                    b-col.pt-2.d-flex.align-items-center.justify-content-center.border-right(cols="2")
                      p.text-center.font-weight-bold Sub-domain Score (Rating)
                    b-col.pt-2.d-flex.align-items-center.justify-content-center.border-right(cols="1")
                      p.text-center.font-weight-bold Domain Score (%)
                    b-col.pt-2.d-flex.align-items-center.justify-content-center.border-right(cols="2")
                      p.text-center.font-weight-bold Domain Rating (Rating)  

                  
                  b-row.h-100.m-0.d-flex.align-items-center.border-bottom.border-left.border-right.no-break(align-v="stretch" v-for="(domain, domain_index) in this.sortedQuestionsObject") 
                    //- Domain names 
                    b-col.d-flex.align-items-center.justify-content-center.print-border-top(cols="3" style="background-color: #2962ad")  
                        p.text-center.font-weight-bold(style="color: white") {{domain.domain}}

                    
                    b-col.p-0.print-border-bottom(cols="6") 
                      b-row.m-0(v-for="(subdomain, subdomain_index) in domain.subdomain")
                        //- Subdomain name  
                        b-col.border-right.d-flex.align-items-center.pt-2(cols="6" style="background-color: #A2BCE0" :class="[{'border-top': subdomain_index != 0}]")
                          p.font-weight-bold {{subdomain.title}} 

                        //- Subdomain score 
                        b-col.border-right.d-flex.justify-content-center.align-items-center.pt-2(cols="2" :class="[{'border-top': subdomain_index != 0}]")
                          p {{Math.floor(subdomainScore(domain.subdomain)[subdomain_index])}} 

                        //- Subdomain rating 
                        b-col.d-flex.justify-content-center.align-items-center.border-right.pt-2(cols="4" :style="backgroundColor(subdomainScore(domain.subdomain)[subdomain_index])" :class="[{'border-top': subdomain_index != 0}]")  
                          p {{getRating(subdomainScore(domain.subdomain)[subdomain_index])}}
                    
                    //- Domain score 
                    b-col.d-flex.align-items-center.justify-content-center.border-right.print-border-top(cols="1")  
                      p {{Math.floor(domainScore(subdomainScore(domain.subdomain)))}}
                    
                    //- Domain rating
                    b-col.d-flex.align-items-center.justify-content-center.print-border-top(cols="2" :style="backgroundColor(domainScore(subdomainScore(domain.subdomain)))")  
                      p.text-center {{getRating(domainScore(subdomainScore(domain.subdomain)))}}
                
                //- Table phone view 
                b-card#summary-table.p-3.m-3(v-else v-for="(domain, domain_index) in this.sortedQuestionsObject")
                  
                  //- Domain name 
                  b-row
                    b-col.d-flex.align-items-center.justify-content-center.p-2(cols="12" style="color: white; background-color: #1f487e")
                      h2.text-center.font-weight-bold(v-b-toggle="`domain-${domain_index}`" style="color: white;") {{domain.domain}}

                  //- Subdomains 
                  div(v-for="(subdomain, subdomain_index) in domain.subdomain")
                    //- Subdomain names 
                    b-row
                      b-col.d-flex.align-items-center.justify-content-center(cols="12" style="background-color: #2962ad")
                        p.text-center.font-weight-bold(style="color: white") {{subdomain.title}}
                    
                    //- Subdomain score 
                    b-row.border
                      b-col(cols="6" style="background-color: #1f487e") 
                        p.text-center(style="color: white") Sub-domain Score (%)
                      b-col(cols="6") 
                        p.text-center  {{subdomainScore(domain.subdomain)[subdomain_index]}} 
                    
                    //- Subdomain rating 
                    b-row.border
                      b-col(cols="6" style="background-color: #1f487e") 
                        p.text-center(style="color: white") Sub-domain Score (Rating) 
                      b-col(cols="6" :style="backgroundColor(subdomainScore(domain.subdomain)[subdomain_index])" ) 
                        p.text-center {{getRating(subdomainScore(domain.subdomain)[subdomain_index])}} 
                  
                  //- Domain score 
                  b-row 
                    b-col(cols="12" style="background-color: #2962ad")
                      p.text-center.font-weight-bold(style="color: white") Total Domain Score
                  b-row.border
                    b-col(cols="6" style="background-color: #1f487e") 
                      p.text-center(style="color: white") Domain Score (%)
                    b-col(cols="6") 
                      p.text-center {{domainScore(subdomainScore(domain.subdomain))}}
                  b-row.border
                    b-col(cols="6" style="background-color: #1f487e") 
                      p.text-center(style="color: white") Domain Score (Rating)
                    b-col(cols="6" :style="backgroundColor(domainScore(subdomainScore(domain.subdomain)))") 
                      p.text-center {{getRating(domainScore(subdomainScore(domain.subdomain)))}}
              
                //- OH index 
                b-row.m-0.pt-3
                  b-col(:cols="$root.inDesktop ? 7 : 0" v-if="$root.inDesktop")
                  b-col.border(:cols="$root.inDesktop ? 3 : 6") 
                    p.font-weight-bold.text-center Your OHI
                  b-col.border-right.border-top.border-bottom(:cols="$root.inDesktop ? 2 : 6" :style="backgroundColor(getOHI)")
                    p.text-center {{getOHI}}%
                NavButtons.pt-4.no-print
                  

</template>

<script>
import NavButtons from '@/components/navbuttons.vue';
import ExcelJS from "exceljs";
import * as FileSaver from 'file-saver';
import axios from "axios";
import printImg from "@/assets/images/printNote.png";
import printColorImg from "@/assets/images/printColorNote.png";

export default {
  
  name: 'OHI',
  components:{
    NavButtons
  },
  data(){
    return{
      printImg,
      printColorImg,
      printNote: false, 
      questionsObject: this.$store.state.allQuestionsObjects,
      columnHeaders:['Domain Name', 'Sub-domain Name', 'Sub-domain Score (%)', 'Sub-domain Score (Rating)', 'Domain Score (%)', 'Domain Score (Rating)']
    }
  },
  computed:{
     sortedQuestionsObject(){
       let order = ['Strategy & Leadership', 'Governance', 'People',  'Finances', 'Digitalisation', 'Communications & Partnerships',  'User-Centric Services'];
       let sortedArr = []
       for (let o of order) {
         const currentObj = this.questionsObject.find(q => q.domain === o);
         sortedArr.push(currentObj)
       }
       return sortedArr;
     },
     getOHI(){
      let resultArr = [];

      // Calculate domain score 
      for(let questionObj of this.sortedQuestionsObject){
        resultArr.push(this.domainScore(this.subdomainScore(questionObj.subdomain)))
      }

      // Compute score from each domain 
      let score = Math.floor(resultArr.reduce((p,c)=>p+c,0)/resultArr.length);

      // Set score in local storage 
      localStorage.setItem('score', score);
      return score;
    },
  },
  methods:{
    async exportExcel(){
      
      // Create workbook, get file, and get worksheet
      let workbook = new ExcelJS.Workbook();
      let {data} = await axios.get('/template/results_template.xlsx', {responseType: 'arraybuffer'});
      await workbook.xlsx.load(data);
      let worksheet = await workbook.getWorksheet('Sheet1');
 
      // Modify worksheet 
      // Write sub-domain score to Column C 
      let row = 4;
      for(let questionObj of this.sortedQuestionsObject){
        let subdomainScores = this.subdomainScore(questionObj.subdomain);
        for(let subdomainScore of subdomainScores){
          let cell = 'C' + row;
          worksheet.getCell(cell).value = subdomainScore/100; 
          row++;
        }
      }

      // Download and save file 
      const bufferData = await workbook.xlsx.writeBuffer();
      const blob = new Blob([bufferData], {type: "application/octet-stream"});
      FileSaver.saveAs(blob, "Your_individual_scoring_report.xlsx");
    },
    async print(){
      // Hide printing note 
      this.printNote = false;

      // Await and set timeout to make sure that printing is not affected 
      await this.$nextTick();
      await this.$nextTick();
      setTimeout(()=>{
            window.print();
      }, 100)
    },
    printAlert(){
      // Show print alert modal 
      this.printNote = true; 
    },
    backgroundColor(score){
      //Background color based on score 
      if(score == 0){
          return {'background-color': '#72A1E5'};
      }else if (score < 50) {
            return {'background-color': '#ff0037'};
      } else if (score >= 50 && score < 70) {
            return {'background-color': '#ffabf2'};
      } else if (score >= 70 && score < 85) {
          return {'background-color': '#d8ffa8'};
      } else {
          return {'background-color': '#5eff00'};
      }
    },
    subdomainScore(subdomainObj){
      // Compute subdomain score 
      return subdomainObj.map(subdomain=>{
          let total = subdomain.rows.length *4;
          let sum = subdomain.rows.reduce((p,c)=>p + (c.selected||0) ,0);
          return sum/total * 100;
      })
    },
    domainScore(subdomainScores){
      // Compute domain score 
      return subdomainScores.reduce((p,c)=>p+c,0)/subdomainScores.length;
    },
    getRating(score) {
      // Get rating based on score
      if (score < 50) {
          return 'Limited';
      } else if (score >= 50 && score < 70) {
          return 'Fair';
      } else if (score >= 70 && score < 85) {
          return 'Good';
      } else {
          return 'Excellent';
      }
    }
  }
}
</script>