<template lang="pug">
    div 
        //- Need help link 
        b-link.font-weight-bold(v-b-toggle.help) Need help?
        b-collapse(id="help")
            b-card
                p Should you face any technical issue, feel free to reach out to ThunderQuote for assistance via their 
                    span 
                        b-link(href="https://chat.whatsapp.com/JQkX1aLgVYO80FQZ4tAqol" target="_blank") WhatsApp Group 
</template>

<script>
export default {
    name:'TechnicalSupport',
}
</script>
