const questions  = () =>{ return {
    id:4,
    domain: "People",
    domainScore: '',
    domainRating: '',
    subdomain:[{
        title: "People & Succession Planning",
        description: "How the organisation is able to plan for its manpower needs, including succession plans",
        rows: [{
            question: "The organisation systematically and strategically develops its Human Resources (HR) plans.",
            ratingDescriptors: [{
                Limited: "The HR plans are not anticipated ahead of time and do not have a defined link to the strategic goals and organisational values.",
                Fair: "The HR plans are created with some basic alignment to strategic goals and organisational values.",
                Good: "The organisation meets the key HR needs of its employees, and develops HR plans which are aligned to strategic goals and organisational values.",
                Excellent: "The organisation anticipates HR needs and systematically and strategically develops robust HR plans that encompass employees and are strongly integrated with the organisation's vision, mission and values. "
            }],
            selected: null
        },
        {
            question: "The organisation uses HR information to track people performance, and identifies and grooms employees for succession of key roles in the organisation.",
            ratingDescriptors: [{
                Limited: "HR information is not used to track people performance and there are no succession plans for key roles.",
                Fair: "HR information is used to track people performance on an ad hoc basis and potential candidates are identified with plans to groom them for succession, although the approach is still vague. ",
                Good: "HR information is used to track people performance across most functions with succession planning and grooming of leaders being aligned to organisational needs.",
                Excellent: 'HR information is extensively leveraged to track people performance which serves as a basis for identifying and grooming high potential employees for succession of key role across all functions.'
            }],
            selected: null
        },
        {
            question: "The organisation ensures that its HR policies are well-defined, communicated and easy to administer.",
            ratingDescriptors: [{
                Limited: "HR policies are not defined, administered, and communicated.",
                Fair: "Basic HR policies exist, but are vaguely defined and communicated and administered on an ad hoc basis.",
                Good: "Most HR policies are well-defined, easy to administer and communicated on a regular basis.",
                Excellent: 'All HR policies are robust, developed with the intention of enabling development, facilitates deployment and two-way communication to ensure ongoing improvements.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Recruitment",
        description: "How the organisation recruits employees to meet organisational needs",
        rows: [{
            question: "The organisation effectively recruits and onboards its employees.",
            ratingDescriptors: [{
                Limited: "A formal recruitment and onboarding process does not exist at the organisation, resulting in  employee requirements and responsibilities being unclear and undefined.",
                Fair: "The organisation establishes a basic recruitment process to meet organisational needs, and new employees are introduced to the organisation through a standardised onboarding programme.",
                Good: "The organisation has a structured recruitment process with clearly defined job roles and responsibilities, and a detailed onboarding programme enabling new employees to transit smoothly into their new role.",
                Excellent: "A robust recruitment system exists within the organisation to select candidates for the best job fit, along with a customisable onboarding programme that effectively supports the transition and communication of the organisation’s mission, vision and values to new employees."
            }],
            selected: null
        },
        {
            question: "The organisation redesigns job roles and workspaces dynamically to meet changing needs of the workforce landscape.",
            ratingDescriptors: [{
                Limited: "Changes to the workforce landscape are not taken into account when designing job roles and workspaces.",
                Fair: "Job roles and workspaces are redesigned in an ad hoc manner to cater to the changing workforce landscape.",
                Good: "Job roles and workspaces are mostly redesigned to meet the changing workforce landscape.",
                Excellent: 'Job roles and workspaces are continuously redesigned and transformed as the organisation is well aware of future workforce demands and needs.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Staff Engagement & Well-being",
        description: "How the organisation engages employees and enhances their well-being to improve organisational health and performance",
        rows: [{
            question: "The organisation suitably engages its employees to perform their roles and responsibilities so as to achieve organisational goals.",
            ratingDescriptors: [{
                Limited: "There is limited employee engagement to encourage fulfilment of job roles and responsibilities. ",
                Fair: "There are some efforts in place to engage employees to perform job roles and responsibilities.",
                Good: "There are key platforms to frequently engage and motivate employees to drive performance and fulfil their roles and responsibilities.",
                Excellent: "There is extensive employee engagement occuring on multiple established platforms to encourage high performance and strong commitment to job responsibilities, largely driven by a clear articulation to the role in meeting the organisation’s purpose, goals and objectives."
            }],
            selected: null
        },
        {
            question: "The organisation enables effective internal communication such as through townhalls, retreats, intranet, email, etc.",
            ratingDescriptors: [{
                Limited: "There are limited or no platforms available for internal communication within the organisation.",
                Fair: "There are some efforts present to develop platforms to enable internal communication within the organisation.",
                Good: "There are key platforms available to enable internal communication within the organisation.",
                Excellent: 'There are multiple established platforms present to enable regular and effective internal communication within the organisation to promote strong commitment to organisational values and strategic goals.'
            }],
            selected: null
        },
        {
            question: "The organisation develops a work environment that enhances employee health and well-being, and caters to diverse employee needs (e.g. Flexible Work Arrangement, Part-time employment, Employee Assistance Programmes, etc.).",
            ratingDescriptors: [{
                Limited: "The work environment is limited to managing hygiene factors.",
                Fair: "The work environment has areas that address employee health and well-being, but is currently a work-in-progress at the organisation.",
                Good: "The work environment reinforces the values of the organisation and is designed to cater to most needs of the organisation curating employee wellness and benefits programmes to ensure employee satisfaction.",
                Excellent: 'The work environment caters to the wide diverse needs of the organisation and a combination of employee wellness and benefits programmes conducted consistently caters to the differing demographics in the organisation.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Learning & Development",
        description: "How the Learning & Development of employees results in higher productivity and personal growth",
        rows: [{
            question: "The organisation systematically designs and implements Learning and Development plans for employees.",
            ratingDescriptors: [{
                Limited: "Learning and Development plans for employees are limited or do not exist.",
                Fair: "Learning and Development plans are implemented in an ad-hoc manner for employees.",
                Good: "Learning and Development plans exist internally for most key areas of work, augmented by external local learning opportunities for employees.",
                Excellent: "Learning and Development plans exist for all functional areas, both internally and externally, including participation at relevant external local and regional forums for employees."
            }],
            selected: null
        },
        {
            question: "The organisation has structures (e.g. supervision processes) in place that ensure social service professionals are delivering services effectively.",
            ratingDescriptors: [{
                Limited: "There is no defined supervision structure in the organisation to engage social service professionals for effective and efficient service delivery.",
                Fair: "There is a basic supervision structure for social service professionals in the organisation, and some definition on the requirements for an effective and efficient delivery of this service. ",
                Good: "There is a well-defined supervision structure for the social service function to ensure that the professionals are able to deliver services effectively an efficiently across most social service functions within the organisation.",
                Excellent: 'There is an established and robust supervision structure (e.g. supervision processes and frameworks) for all social service professionals (e.g. Allied Health Professionals, Social Workers, Counsellors) and includes regular participation of local experts to enhance knowledge acquisition.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Performance Management & Recognition",
        description: "How the employee performance management and recognition channels encourage employees to achieve high performance and productivity",
        rows: [{
            question: "The organisation establishes an effective performance management system to objectively measure outcomes and reinforce desired behaviours.",
            ratingDescriptors: [{
                Limited: "There is limited or no definition of high performance, and performance management is not aligned to drive the reinforcement of desired behaviours, organisational values and objectively measure outcomes.",
                Fair: "The organisation is starting to take steps to define what is high performance, and is beginning to measure and reinforce these behaviours and outcomes.",
                Good: "The organisation has standardised definition of high performance, and performance metrics have a linkage to the strategy of the organisation to help reinforce desired behaviours and organisational values in most functions of the organisation.",
                Excellent: "The organisation has a robust definition of what high performance is, and supports productive behaviours with performance metrics having a clear linkage to organisational targets that reinforce desired behaviours for all functions across the organisation."
            }],
            selected: null
        },
        {
            question: "The organisation establishes mechanisms to incentivise and retain employees, such as rewards and recognition programme.",
            ratingDescriptors: [{
                Limited: "The rewards and recognition programme is not present or is limited with little effort to incentivise and retain employees.",
                Fair: "There is a plan to have a structured rewards and recognition programme to retain employees but is a work in progress.",
                Good: "There is a structured rewards and recognition programme that drives engagement and enhances retention amongst employees.",
                Excellent: 'There is a robust rewards and recognition programme to retain employees with a visible value proposition to prospective employees.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Volunteer Management",
        description: "How the organisation optimise and manage their volunteer resources strategically to achieve organisational goals",
        rows: [{
            question: "The organisation establishes a robust volunteer management strategy supported by volunteer-friendly culture with board and senior management buy in.",
            ratingDescriptors: [{
                Limited: "A volunteer management strategy does not exist at the organisation.",
                Fair: "The organisation is starting to develop or has a basic volunteer management strategy in place.",
                Good: "The organisation has a well-defined volunteer management strategy in place.",
                Excellent: "The organisation has a robust volunteer management strategy, supported by volunteer-friendly culture with board and senior management buy in."
            }],
            selected: null
        },
        {
            question: "The organisation establishes a structure, processes, and systems to strengthen volunteer recruitment, training, engagement, and review of volunteer management.",
            ratingDescriptors: [{
                Limited: "The organisation has no proper or limited structure, processes, and systems in place for volunteer management. ",
                Fair: "The organisation has some structure, processes, and systems for volunteer management.",
                Good: "The organisation has a well-defined structure, processes, and systems in place for volunteer management.",
                Excellent: 'The organisation has a robust structure, processes, and systems in place which are consistently used for the review of volunteer management.'
            }],
            selected: null
        },
        {
            question: "The organisation establishes volunteering partnerships with stakeholders (e.g., corporate, educational institutes) to achieve the organisational goals.",
            ratingDescriptors: [{
                Limited: "The organisation does not engage volunteers, or mainly engages individual volunteers.",
                Fair: "The organisation has ad-hoc volunteering partnerships with stakeholders.",
                Good: "The organisation establishes sustained volunteering partnerships with stakeholders. The organisation looks out for new partnerships on an ad-hoc basis.",
                Excellent: 'The organisation establishes sustained volunteering partnerships with stakeholders. The organisation establishes a process to constantly look out for and establish new partnerships.'
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    },
    {
        title: "Process Readiness",
        description: "How the organisation ensures comprehensiveness in processes to meet people practice needs",
        rows: [{
            question: "The organisation establishes process management that enables continuous improvement of how its people practices function.",
            ratingDescriptors: [{
                Limited: "The organisation has limited or no process management approach to track and monitor continuous improvement of people practices.",
                Fair: "The organisation has taken some efforts to utilise a process management approach in some elements of people practices. ",
                Good: "The organisation has an effective process management approach that drives periodic improvement of its people practices.",
                Excellent: "The organisation has a comprehensive process management system that drives continuous improvement of its people practices, with established platforms in place to collect feedback."
            }],
            selected: null
        },
        {
            question: "The organisation ensures that its current processes sufficiently meet its people practice needs, such as through SOPs.",
            ratingDescriptors: [{
                Limited: "The organisation has limited or no SOPS to drive performance of its basic people practice needs. ",
                Fair: "The organisation has some SOPS for basic people practice needs.",
                Good: "The organisation has largely documented the SOPs for current processes, that sufficiently meets most people practice needs.",
                Excellent: 'The organisation has extensively documented the SOPs that sufficiently meets and anticipates people practice needs.  '
            }],
            selected: null
        }],
        subdomainScore: '',
        subdomainRating: ''
    }]
}
}

export default questions;