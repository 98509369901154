<template lang="pug">
  div.m-4
    //- Input field
    div
      h2 Enter the OTP sent to your email.
      p Please check your spam/junk mail folder. 
      b-form-input(v-if="$root.inMobile" class="input-mobile mt-4 mb-2" type="number" placeholder="Enter your OTP" v-model="totp")
      b-form-input(v-else class="input mt-4 mb-2" type="number" placeholder="Enter your OTP" v-model="totp")
    
    //- Confirm button 
    div(class="cover-button")
      b-button(variant="primary" size= 'lg' @click="confirm" :disabled="confirmButtonDisabled") Confirm          
</template>

<script>
export default {
  name: 'Otp',
  data(){
    return{
      totp: null,
      confirmButtonDisabled: false
    }
  },
  methods:{
    setConfirmButtonDisabled(payload){
      this.confirmButtonDisabled = payload;
    },
    async confirm(){
      // Disable button 
      this.setConfirmButtonDisabled(true); 

      if(this.totp == 123456 && this.$route.path.includes('register')){
        this.$router.push('/register/users');
      }
      // On success, mark authenticated & sync data 
      else if(this.totp == 123456){
        this.$store.commit('markAuthenticated');
        this.$router.push('/details');
      }else{
        alert('Invalid code!');
      }

      this.setConfirmButtonDisabled(false); 
      

    }
  }
}
</script>