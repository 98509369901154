const questions = () =>{
 
    return {
        id:1,
        domain: "Communications & Partnerships",
        domainScore: '',
        domainRating: '',
        subdomain:[{
            title: "External Communication & Branding",
            description: "How the organisation builds its brand reputation and engagement with users and the public through various platforms",
            rows: [{
                question: "The organisation develops and builds its brand reputation through effective external communication and public relations (PR) initiatives.",
                ratingDescriptors: [{
                    Limited: "The organisation has limited or no external communication and public relations initiatives to develop and build its brand reputation.",
                    Fair: "The organisation has developed some external communication and public relations initiatives to develop and build its brand reputation on an ad hoc basis.",
                    Good: "The organisation has effectively developed external communication and public relations initiatives to develop and build its brand reputation continuously.",
                    Excellent: "The organisation has extensive and comprehensively documented external communication and public relations initiatives to build its brand reputation, and actively seeks out opportunities to develop its brand further."
                }],
                selected: null
            },
            {
                question: "The organisation uses technology to reach out to and engage users and stakeholders, such as through interactive websites and other social platforms.",
                ratingDescriptors: [{
                    Limited: "The organisation rarely uses or does not use technology to reach out to, and engage with, users and stakeholders.",
                    Fair: "The organisation sometimes uses technology to reach out to and engage with users and stakeholders, through a few channels and platforms, on an ad hoc basis.",
                    Good: "The organisation regularly uses technology to reach out to, and engage with, users and stakeholders through well-defined channels e.g. an interactive website and other social platforms.",
                    Excellent: "The organisation extensively and actively uses technology to reach out to, and engage with, users and stakeholders, through known established channels e.g. an interactive and engaging website and all relevant social platforms (Facebook, Instagram, YouTube, etc)."
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        },
        {
            title: "Partnerships & Alliances",
            description: "How the organisation develops and maintains its partnerships and alliances",
            rows: [{
                question: "The organisation has developed effective existing partnerships and alliances that add value to its programmes and target users.",
                ratingDescriptors: [{
                    Limited: "The organisation has few or no existing partnerships and alliances that add value to its programmes and target users.",
                    Fair: "The organisation has some existing partnerships and alliances that add value to its programmes and target users.",
                    Good: "The organisation has a significant number of effective existing partnerships and alliances that add value to its programmes and target users.",
                    Excellent: "The organisation has a robust system in place to manage its many effective existing partnerships and alliances that add value to its programmes and target users such as through co-creation of services and solutions."
                }],
                selected: null
            },
            {
                question: "The organisation explores and develops partnerships and alliances strategically, such as through the use of information and data.",
                ratingDescriptors: [{
                    Limited: "The organisation rarely or does not explore and develop partnerships and alliances strategically.",
                    Fair: "The organisation explores and develops partnerships and alliances strategically, in a conventional manner from a few key sources.",
                    Good: "The organisation regularly explores and develops partnerships and alliances strategically, in a data-driven manner from a number of sources.",
                    Excellent: "The organisation actively and consistently explores and develops partnerships and alliances strategically, in a data-driven manner from as many sources as possible."
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        },
        {
            title: "Community Involvement",
            description: "How the organisation engages with the community and individuals it serves, along with identifying collaborative opportunities with policymakers and regulatory bodies",
            rows: [{
                question: "The organisation connects and engages with the community and individuals that it serves.",
                ratingDescriptors: [{
                    Limited: "The organisation rarely connects or does not connect and engage with the community and individuals that it serves.",
                    Fair: "The organisation sometimes connects and engages with the community and individuals that it serves.",
                    Good: "The organisation often connects and engages with the community and individuals that it serves.",
                    Excellent: "The organisation regularly and effectively connects and engages with the community and individuals that it serves, creating opportunities for collaboration."
                }],
                selected: null
            },
            {
                question: "The organisation partners with policymakers and regulatory bodies to advance its objectives.",
                ratingDescriptors: [{
                    Limited: "The organisation rarely or does not partner with policymakers and regulatory bodies to advance its objectives.",
                    Fair: "The organisation sometimes partners with policymakers and regulatory bodies to advance its objectives.",
                    Good: "The organisation regularly and effectively partners with policymakers and regulatory bodies to advance its objectives.",
                    Excellent: "The organisation consistently and actively partners with policymakers and regulatory bodies to advance its objectives, taking initiative to reach out to minstries to understand areas they can create an impact in."
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        },
        {
            title: "Process Readiness",
            description: "How the organisation ensures comprehensiveness in processes to meet communications and partnerships needs",
            rows: [{
                question: "The organisation establishes process management that enables continuous improvement of its comunications & partnerships function.",
                ratingDescriptors: [{
                    Limited: "The organisation has limited or no process management approach to track and monitor continuous improvement of its communications & partnerships function.",
                    Fair: "The organisation has taken some efforts to utilise a process management approach in some elemnts of its communications & partnerships function. ",
                    Good: "The organisation has effective process management approach that drives periodic improvement of its communications & partnerships function.",
                    Excellent: "The organisation has a comprehensive process management system that drives continuous improvement of its communications & partnerships function, with established platforms in place to collect feedback."
                }],
                selected: null
            },
            {
                question: "The organisation ensures that its current communications & partnerships processes sufficiently meets its needs, such as through SOPs.",
                ratingDescriptors: [{
                    Limited: "The organisation has limited or no SOPS to drive performance of its basic communications & partnerships needs.",
                    Fair: "The organisation has some SOPS for basic organisational needs.",
                    Good: "The organisation has largely documented the SOPs for current processes that sufficiently meets most organisational needs.",
                    Excellent: "The organisation has extensively documented the SOPs for current processes that sufficiently meets and anticipates organisational needs."
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        }]
    }
}
export default questions;