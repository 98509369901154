const questions =  () =>{ return {
        id:3,
        domain: 'Governance',
        domainScore: '',
        domainRating: '',
        subdomain:[{
            title: 'Board Compliance & Composition',
            description: 'How the Board ensures that it is compliant with governance practices and has diversity in areas of expertise to sufficiently guide the organisation',
            rows: [{
                question: 'The organisation adheres to the Code of Governance for Charities & IPCs (April 2017).',
                ratingDescriptors: [{
                    Limited: 'There is limited adherence to the Code of Governance for Charities & IPCs (April 2017).',
                    Fair: 'There are some efforts taken to adhere to the Code of Governance for Charities & IPCs (April 2017).',
                    Good: 'The organisation aligns most of its policies to the Code of Governance for Charities & IPCs (April 2017).',
                    Excellent: 'The organisation has strict adherence to the Code of Governance for Charities & IPCs (April 2017), using it to inform policymaking and procedures.'
                }],
                selected: null
            },
            {
                question: 'The Board has the needed diversity, expertise and connections.',
                ratingDescriptors: [{
                    Limited: 'The Board is homogenous in terms of representation, and members provide their views, guidance, experience only when there is a request to do so by the Management Team of the organisation.',
                    Fair: 'There is some diversity amongst the Board in terms of representation, and members provide their views, guidance, experience and provide connections of individuals with specialised skills on an ad hoc basis.',
                    Good: 'There is a well-defined diversity of the Board in terms of representation, and members provide their views, guidance, experience, and provide connections of individuals with specialised skills most of the time.',
                    Excellent: 'There is extensive diversity in the Board with representation in terms of gender, ethnic and professional background and all Board members actively share their views, guidance, experience, and provide connections of resources with specialised skills to support all requirements of the organisation.'
                }],
                selected: null
            },
            {
                question: 'The Board ensures that there are Board Committees present to oversee relevant areas of the organisation.',
                ratingDescriptors: [{
                    Limited: 'While there is a board of governance present, currently there are no Board Committees established.',
                    Fair: 'There are Board Committees overseeing basic areas (Audit Committee, Finance Committee) of the organisation.',
                    Good: 'There are Board Committees overseeing key areas (Audit Committee, Finance Committee, Fundraising Committee, Programmes & Services Committee) of the organisation.',
                    Excellent: 'There are Board Committees actively overseeing all relevant compliance areas of the organisation.'
                }],
                selected: null
            },
            {
                question: 'The Board ensures effective resource planning, such as recruitment and onboarding of Board members and succession planning for key roles.',
                ratingDescriptors: [{
                    Limited: 'Board resource planning is not strategically undertaken, and resource planning is conducted in an ad hoc fashion.',
                    Fair: 'The Board establishes a basic recruitment process, and standardised onboarding programme to meet its needs, but succession planning for key Board roles is still vague.',
                    Good: 'The Board establishes a structured recruitment process and detailed onboarding programme to meet its needs, and succession planning and grooming for key Board roles are aligned to governance procedures.',
                    Excellent: 'The Board establishes a robust recruitment system to select the candidates for the best job fit, a customisable onboarding programme to effectively communicate the organisation’s mission, vision and values to new members, and ensures succession planning and grooming for key Board roles is aligned to governance procedures and meets relevant expectations.'
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''
        },
        {
            title: 'Role of Board',
            description: 'How the Board plays its role (e.g. in fund development). </br> </br>\
            <i>(*The definition of Fund Development is inclusive and ongoing efforts to fund the organisation. This includes fundraising events/programmes sustaining funding sources by developing partnerships with corporations, communities and individuals, as well as actively participating in, and applying for funding.) </i>',
            rows: [{
                question: 'The Board actively participates in fund development.',
                ratingDescriptors: [{
                    Limited: 'There is limited involvement of the Board in fund development, with participation occurring only if required by the Management Team of the organisation.',
                    Fair: 'Selected members of the Board participate in fund development as needed for specific programmes.',
                    Good: 'The Board participates in fund development most of the time, and actively drives fundraising efforts.',
                    Excellent: "The Board participates in fund development in an extensive and active manner, such as through using its strategic connections and driving fundraising efforts."	
                }],
                selected: null
            },
            {
                question: "The Board effectively differentiates between its role and that of the organisation's Senior Management Team (SMT).",
                ratingDescriptors: [{
                    Limited: 'There is limited clarity in the roles of Board members and Management Team members of the organisation.',
                    Fair: 'There are some efforts to distinguish between the roles of Board members and Management Team members, although the approach is still largely vague.',
                    Good: 'The role of the Board is well documented, and the delineation between Board members and Management Team members is clear with efforts to minimise overlaps between strategic guidance and involvement in daily operations.',
                    Excellent: 'The role of the Board is clearly documented and practised, with all members providing only strategic guidance and are not involved in daily operations and decision-making.'	
                }],
                selected: null
            },
            {
                question: "The Board provides appropriate guidance to the organisation by periodically reviewing and approving strategic plans.",
                ratingDescriptors: [{
                    Limited: 'The Board provides limited or no guidance to the organisation on its strategic plans.',
                    Fair: "The Board provides some ad hoc guidance on the organisation's strategic plans as requested.",
                    Good: 'The Board participates and provides continuous guidance on its strategic plan.',
                    Excellent: 'The Board provides active and robust guidance to the organisation on its strategic plans and regularly reviews the plans within the year.'	
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''  
        },
        {
            title: 'Risk Management',
            description: 'How the organisation protects its data and establishes processes to mitigate risk and ensure regulatory compliance',
            rows: [{
                question: 'The organisation establishes processes for risk identification, analysis and evaluation.',
                ratingDescriptors: [{
                    Limited: 'The organisation has limited or no processes for risk identification, analysis and evaluation.',
                    Fair: 'The organisation has some documented processes for risk identification, analysis and evaluation.',
                    Good: 'The organisation has effectively documented processes for risk identification, analysis and evaluation.',
                    Excellent: "The organisation has extensively and robustly documented processes for risk identification, analysis and evaluation, such as risk management plans and protocols."	
                }],
                selected: null
            },
            {
                question: "The organisation complies with PDPA guidelines to collect, use or disclose users' personal data.",
                ratingDescriptors: [{
                    Limited: 'The organisation does not conduct reviews for compliance to PDPA guidelines.',
                    Fair: 'The organisation conducts ad-hoc reviews for compliance to PDPA guidelines.',
                    Good: 'The organisation conducts planned reviews for compliance to PDPA guidelines.',
                    Excellent: 'The organisation conducts frequent and continuous reviews for compliance to PDPA guidelines.'	
                }],
                selected: null
            },
            {
                question: "The organisation has reasonable security arrangements to protect personal and other organisational data.",
                ratingDescriptors: [{
                    Limited: 'The organisation has limited or no security arrangements to protect personal and other organisational data.',
                    Fair: "The organisation has adequate security arrangements to protect key personal and other organisational data.",
                    Good: 'The organisation has effective security arrangements to protect all personal and other organisational data.',
                    Excellent: 'The organisation has extensive, comprehensive and pre-emptive security arrangements to protect personal and other organisational data, which are clearly defined and communicated to employees and reviewed regularly by external specialists.'	
                }],
                selected: null
            }],
            subdomainScore: '',
            subdomainRating: ''  
        }]
}
}

export default questions;